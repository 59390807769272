goog.declareModuleId('yext.ui.components.select.standard.SelectOption');

import {optionStates} from '/ui/components/select/model/optionstates';
import {SelectOptionLabel} from '/ui/components/select/standard/selectoptionlabel';
import {Option} from '/ui/components/select/types/option';

import * as styles from '/ui/components/select/standard/standard.module.scss';

/**
 * @param {{
 *   additionalCssClass?: string,
 *   ariaAttributes?: ?,
 *   children?: React.ReactNode,
 *   option: Option,
 *   onSelectChange: function(!Array<?>): void,
 * }} props
 */
export function SelectOption({
  ariaAttributes,
  additionalCssClass,
  children,
  option,
  onSelectChange,
}) {
  const checked = Option.getStatus(option) === optionStates.SELECTED;
  const listItemClasses = ['ui-singleselect-option js-select-option'];
  const buttonClasses = ['ui-select-row ui-select-button js-select-target', option.className];

  if (checked) {
    buttonClasses.push('ui-select-row--selected');
  }
  if (option.disabled) {
    listItemClasses.push(`${styles.uiSelectOptionDisabled} ui-select-option-disabled js-select-option-disabled`);
  }
  if (additionalCssClass) {
    buttonClasses.push(additionalCssClass);
  }

  return (
    <li
      tid={option.tid}
      className={listItemClasses.join(' ')}
      role="none"
    >
      {children}
      {/* This is not your average, everyday button. */
        /* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
      <button
        type="button"
        tabIndex="-1"
        disabled={option.disabled}
        className={buttonClasses.join(' ')}
        onClick={() => onSelectChange([option.value])}
        {...ariaAttributes}
        aria-selected={checked}
        data-pendo={option.pendoId}
      >
        <SelectOptionLabel option={option} />
      </button>
    </li>);
}
