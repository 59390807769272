goog.declareModuleId('yext.ui.components.KeyboardAccessibleList');

import {logError} from '/ui/lib/errors';

function handleKeyboardNavigation(itemSelector, focusTargetSelector, onUnselect, event) {
  const currentItem = document.activeElement?.closest(itemSelector);
  switch (event.key) {
    case 'ArrowDown':
    case 'ArrowUp':
      // TODO(amullings): Move focus out of the list?
      event.preventDefault();
      let nextItem;
      if (currentItem) {
        nextItem = ['ArrowDown', 'Down'].includes(event.key)
          ? getNextOption(itemSelector, currentItem, focusTargetSelector)
          : getPreviousOption(itemSelector, currentItem, focusTargetSelector);
        if (nextItem && onUnselect) {
          onUnselect(currentItem);
        }
      } else {
        nextItem = document.activeElement?.querySelector(itemSelector);
      }
      if (nextItem) {
        focus(focusTargetSelector, nextItem);
      }
      break;
    // TODO(amullings): Home/End
    // TODO(amullings): Handle simple search by typing characters
  }
}

function getNextOption(itemSelector, current, focusTargetSelector) {
  return getSibling(itemSelector, current, elem => elem.nextSibling, focusTargetSelector);
}

function getPreviousOption(itemSelector, current, focusTargetSelector) {
  return getSibling(itemSelector, current, elem => elem.previousSibling, focusTargetSelector);
}

function getSibling(itemSelector, current, siblingFunc, focusTargetSelector) {
  const sibling = siblingFunc(current);
  if (!sibling) {
    return null;
  }
  if (!sibling.matches(itemSelector)) {
    return getSibling(itemSelector, sibling, siblingFunc, focusTargetSelector);
  }
  const target = sibling.querySelector(focusTargetSelector);
  if (target && target.disabled) {
    return getSibling(itemSelector, sibling, siblingFunc, focusTargetSelector);
  }
  return sibling;
}

function focus(focusTargetSelector, listItem) {
  if (!listItem) {
    logError('KeyboardAccessibleList: Could not focus (null listItem)');
  }
  let target = listItem.querySelector(focusTargetSelector);

  // If the target is a label, we want to focus it's associated control.
  target = target.control || target;
  if (!target) {
    logError('KeyboardAccessibleList: Could not focus (null target)');
  }
  target.focus();
}

/** @suppress {checkTypes} */
class KeyboardAccessibleListImpl extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyDown = handleKeyboardNavigation
      .bind(null, props.itemSelector, props.focusTargetSelector, props.onUnselect);
  }

  render() {
    return (
      <ul
        ref={this.props.listRef}
        tabIndex={this.props.tabIndex}
        className={this.props.classList.join(' ')}
        onKeyDown={this.props.ignoreKeydown ? undefined : this.handleKeyDown}
        style={{height: this.props.height}}
        // use string keys to prevent advanced compilation
        {...{'role': 'group'}}
        {...this.props.ariaAttributes}
      >
        {this.props.children}
      </ul>
    );
  }
}

export const KeyboardAccessibleList = /** @type {?} */ (React.forwardRef(
  (props, ref) => <KeyboardAccessibleListImpl {...props} listRef={ref}/>));
