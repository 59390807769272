goog.declareModuleId('yext.ui.components.select.native.NativeSelectPlaceholder');

export function NativeSelectPlaceholder(props) {
  if (props.multiple) {
    return null;
  }

  // Displays a placeholder that cannot be selected, and is also hidden from the
  // option list in most browsers (notably not Safari).
  // https://stackoverflow.com/questions/5805059/how-do-i-make-a-placeholder-for-a-select-box/8442831#8442831
  return <option disabled hidden value="">{props.children}</option>;
}
