/**
 * @param {Function} f The function to debounce
 * @param {number} interval Interval to debounce by
 * @param {Object=} opt_scope Optional scope to apply the function with
 *
 * @return {function(...?): undefined} The debounced function
 */
export function debounce(f, interval, opt_scope) {
  let timeoutId = 0;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      f.apply(opt_scope, args);
    }, interval);
  };
}
