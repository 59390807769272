/**
 * @fileoverview Visual states for each select option
 */
goog.declareModuleId('yext.ui.components.select.model.optionstates');

export const optionStates = Object.freeze({
  SELECTED: Symbol('UI_SELECT_SELECTED'),
  INDETERMINATE: Symbol('UI_SELECT_INDETERMINATE'),
  UNSELECTED: Symbol('UI_SELECT_UNSELECTED'),
});
