import yext from 'goog:yext';

import {slowRegisterComponentForJsonData} from '/ui/lib/easyreact';

export function ForgotPassword({
  emailPasswordLink,
  redirectUrl,
  authenticityToken,
}) {
  return (
    <div id="retrieve" className="content">
      <h2 className="forgot-heading">{yext.msg('Forgot Your Password?')}</h2>
      <p className="forgot-text">
        {yext.msg('Enter the email address or username that you use to log into your account and we will send you an email with a link to reset your password.')}
      </p>
      <form action={emailPasswordLink} method="post" acceptCharset="utf-8" encType="application/x-www-form-urlencoded">
        <input type="hidden" name="authenticityToken" value={authenticityToken}/>
        <input type="submit" className="input-submit"/>
        <input type="text" name="email" className="yext-login__input" placeholder={yext.msg('Email / Username')}/>
        <button type="submit" className="yext-login__button yext-login__button--primary yext-login__button--submit">
          <span>{yext.msg('Send')}</span>
        </button>
        <div className="forgot-cancel"><a href={redirectUrl}>{yext.msg('Cancel')}</a></div>
      </form>
    </div>);
}

slowRegisterComponentForJsonData('ForgotPassword', ForgotPassword);
