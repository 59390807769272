goog.declareModuleId('yext.ui.components.select.native.NativeSelectOption');

export function NativeSelectOption(props) {
  return (
    <option
      value={props.option.value}
      disabled={props.option.disabled}
    >
      {props.option.label}
    </option>
  );
}
