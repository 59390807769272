import * as styles from "/tools/js/svg/templates/svg.module.scss";
const SvgSearch = ({ title, titleId, ...props }) => {
  props = Object.assign({}, props, {
    "aria-hidden": true,
  });
  if (!props.noAlign) {
    const className = (props.className || "") + " " + styles.alignIcon;
    props = Object.assign({}, props, {
      className,
    });
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.917 8.607a3.755 3.755 0 0 1-3.75 3.75 3.755 3.755 0 0 1-3.75-3.75 3.755 3.755 0 0 1 3.75-3.75 3.755 3.755 0 0 1 3.75 3.75zm4.286 6.964c0-.284-.117-.56-.31-.753l-2.87-2.871a5.89 5.89 0 0 0-4.856-9.233 5.89 5.89 0 0 0-5.893 5.893 5.89 5.89 0 0 0 9.232 4.855l2.871 2.863c.193.2.47.318.754.318.586 0 1.071-.486 1.071-1.072z"
      />
    </svg>
  );
};
export default SvgSearch;
