// @ts-nocheck

/**
 * For every element in an object/map/hash calls a function and inserts the
 * result into a new object.
 * @param {?Object<K,V>} obj The object over which to iterate.
 * @param {function(this:T,V,?,?Object<K,V>):R} f The function to call for every
 *     element. This function takes 3 arguments (the value, the key and the
 *     object) and should return something. The result will be inserted into a
 *     new object.
 * @param {T=} opt_obj This is used as the 'this' object within f.
 * @return {!Object<K,R>} a new object with the results from f.
 * @template T,K,V,R
 */
function map(obj, f, opt_obj) {
  const res = {};
  for (const key in obj) {
    res[key] = f.call(/** @type {?} */ (opt_obj), obj[key], key, obj);
  }
  return res;
}

function validateProps(propInfo, base64Props) {
  for (const prop of Object.keys(propInfo)) {
    if (!base64Props.hasOwnProperty(prop)) {
      throw new Error(`Expected prop "${prop}" missing in response from server.`);
    }
  }

  for (const prop of Object.keys(base64Props)) {
    if (!propInfo[prop]) {
      throw new Error(`Unexecpected prop "${prop}" present in response from server, but not handled in JavaScript.`);
    }
  }
}

/**
 * Register a component to be rendered with protocol buffer data.
 *
 * Intended to be used with the renderWithProto method in
 * src/com/yext/play/common/app/controllers/React.java
 *
 * USAGE:
 * import {registerComponent} from '/ui/lib/easyreact';
 * import OverviewData from 'goog:proto.foostorm.OverviewData';
 *
 * export function MyComponent({data}) {
 *   // your stuff here
 * }
 *
 * registerComponent('MyComponent', MyComponent, {data: OverviewData.deserializeBinary});
 *
 * @param {string} name
 * @param {?} component
 * @param {?} propInfo
 */
export function registerComponent(name, component, propInfo) {
  document.querySelectorAll(scriptByNameSelector(name)).forEach( e => {
    const props = parseProtoProps(e, propInfo);
    reactRender(e, component, props);
  });
}

const scriptByNameSelector = name => `script[data-react-id="${name}"]`;

/**
 *
 * @param {Element} e
 * @param {?} propInfo
 *
 * @returns {?Object}
 */
function parseProtoProps(e, propInfo) {
  const base64Props = JSON.parse(e.dataset.props);
  validateProps(propInfo, base64Props);

  return map(propInfo, (deserializeBinary, prop) => {
    return deserializeBinary(base64Props[prop]);
  });
}

/**
 * Register a component to be rendered with JSON data.
 *
 * Prefer using the registerComponent function instead of this because
 * registerComponent provides better type-safety.
 *
 * Intended to be used with the render method in
 * src/com/yext/play/common/app/controllers/React.java
 *
 * USAGE:
 * import {registerComponentForJsonData} from '/ui/lib/easyreact';
 *
 * export function MyComponent(props) {
 *   // your stuff here
 * }
 *
 * registerComponentForJsonData('MyComponent', MyComponent);
 *
 * @param {string} name
 * @param {?} component
 */
export function registerComponentForJsonData(name, component) {
  document.querySelectorAll(scriptByNameSelector(name)).forEach( e => {
    const props = parseJsonProps(e);
    reactRender(e, component, props);
  });
}

/**
 *
 * @param {Element} e
 *
 * @returns {!Object}
 */
function parseJsonProps(e) {
  const propsJson = e.dataset['props'] || e.textContent;
  return propsJson ? /** @type {!Object} */ (JSON.parse(propsJson)) : {};
}

/**
 * Wait for DOMContentLoaded, then register a component to be rendered with JSON
 * data.
 *
 * Prefer using the registerComponent or regsiterComponentForJsonData function
 * instead of this because your page will load faster.
 *
 * Intended to be used with the render method in
 * src/com/yext/play/common/app/controllers/React.java
 *
 * The component will be rendered after the DOMContentLoaded event. This means
 * your UI will load slower and users may see a blank page for a moment before
 * the component renders.
 *
 * USAGE:
 * import {registerComponentForJsonData} from '/ui/lib/easyreact';
 *
 * export function MyComponent(props) {
 *   // your stuff here
 * }
 *
 * registerComponentForJsonData('MyComponent', MyComponent);
 *
 * @param {string} name
 * @param {?} component
 */
export function slowRegisterComponentForJsonData(name, component) {
  document.addEventListener('DOMContentLoaded', () => {
    registerComponentForJsonData(name, component);
  });
}

/**
 * Create a React component using React 18.
 *
 * USAGE:
 * import {createReactComponent} from '/ui/lib/easyreact';
 * import MyComponent from './MyComponent';
 *
 * createReactComponent({
 *  'WhateverTheReactIdAttributeIs': MyComponent,
 * });
 *
 * It is recommended (but not required) to use the same react ID as the name of the component.
 *
 * @param {!Object<string, ?>} componentMap A map of react ID to the component that will be rendered.
 */
export function createReactComponent(componentMap) {
  document.querySelectorAll('script[data-react-id]').forEach( e => {
    const props = parseJsonProps(e);
    const componentName = e.dataset['reactId'];
    if (!componentMap[componentName]) {
      return;
    }

    reactRender(e, componentMap[componentName], props);
  });
}

/**
 * Renders a component with the correct React Version based on what is available
 *
 * @param {Element} scriptHost
 * @param {?} component
 * @param {?Object} props
 */
function reactRender(scriptHost, component, props) {
  const host = document.createElement('div');
  scriptHost.parentNode.insertBefore(host, scriptHost);

  if (ReactDOM.createRoot) {
    ReactDOM.createRoot(host).render(React.createElement(component, props));
  } else {
    ReactDOM.render(React.createElement(component, props), host);
  }
}
