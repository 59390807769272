goog.declareModuleId('yext.ui.lib.uid');

if (!window['__yext_uid']) {
  window['__yext_uid'] = 0;
}

/**
 * Create a new unique ID for the page
 * @return {string}
 */
export function newUid() {
  window['__yext_uid'] += 1;
  return 'y' + window['__yext_uid'];
}

/**
 * Create a new unique ID for the page
 * @return {string}
 */
export function useUid() {
  const [uid] = React.useState(newUid);
  return uid;
}
