/**
 * @fileoverview Select component main React interface. Manages all data and
 * state
 */
// @ts-nocheck
goog.declareModuleId('yext.ui.components.select.SelectController');

import {optionStates} from '/ui/components/select/model/optionstates';
import {OptionStateTree} from '/ui/components/select/model/optionstatetree';
import {SelectRoot} from '/ui/components/select/root/selectroot';
import {Config} from '/ui/components/select/types/config';
import {Option} from '/ui/components/select/types/option';

/**
 * @param {!Array<!Option>} selectedOptions of selected options
 * @param {{
 *   placeholder: (string|undefined),
 *   multiselect: (boolean|undefined),
 *   multiselectLabeller: ((function(!Array<!Option>): string)|undefined),
 *   singleselectLabeller: ((function(!Array<!Option>): string)|undefined),
 * }} config
 * @param {?{label: React.ReactNode}} defaultOption
 * @returns {React.ReactNode} A short string describing the selected options
 */
function selectLabel(
  selectedOptions,
  {
    placeholder,
    multiselect,
    multiselectLabeller,
    singleselectLabeller,
  },
  defaultOption,
) {
  let labelText = '';
  if (multiselect) {
    if (placeholder && !selectedOptions.length) {
      labelText = placeholder;
    } else if (multiselectLabeller) {
      labelText = multiselectLabeller(selectedOptions);
    }
  } else {
    if (selectedOptions.length) {
      if (singleselectLabeller) {
        labelText = singleselectLabeller(selectedOptions);
      } else {
        labelText = selectedOptions[0].label;
      }
    } else {
      if (placeholder) {
        labelText = placeholder;
      } else if (defaultOption) {
        labelText = defaultOption.label;
      }
    }
  }

  return labelText;
}

function countTotalChildren(options) {
  return options.length + options
    .filter(o => o.options)
    .map(o => countTotalChildren(o.options))
    .reduce((total, count) => total + count, 0);
}


/**
 * @param {!Array<string>} values
 * @param {!Array<!Option>} options
 * @returns {!Array<!Option>}
 */
function getOptionsWithValues(values, options) {
  const matchingOptions = [];
  const getMatchingOptionsInSubtree = root => {
    if (values.includes(root.value)) {
      matchingOptions.push(root);
    }

    if (root.options) {
      root.options.forEach(getMatchingOptionsInSubtree);
    }
  };

  options.forEach(getMatchingOptionsInSubtree);
  return matchingOptions;
}

/**
 * @param {Config} config
 * @param {!Array.<Option>} options
 * @return {!Array.<string>|string}
 */
function getDefaultSelection({selectedOptions = [], multiselect, placeholder}, options) {
  // If no placeholder is provided for a single-select, select the first
  // non-disabled option as in native selects
  let values = selectedOptions;
  if (values.length == 0
      && !multiselect
      && !placeholder) {
    const firstOption = options.find(o => !o.disabled);
    if (firstOption) {
      if (firstOption.options) {
        values = getDefaultSelection(
          {selectedOptions, multiselect, placeholder},
          firstOption.options);
      } else {
        values = [firstOption.value];
      }
    }
  }
  return values;
}

// Used to filter items
function optionContainsTerm(option, term, diacriticsNormalized = true) {
  let label = option.textContent || '';
  if (typeof(option.label) === 'string') {
    label = option.label;
  }
  // Diacritic normalization works by first splitting into character + diacritic, (e.g. é to e + ')
  // and then removing all the diacritic symbols
  if (diacriticsNormalized) {
    const normalizedLabel = label
      .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    let normalizedSecondaryText = '';
    if (option.secondaryText) {
      normalizedSecondaryText = option.secondaryText
        .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
    const labelIncludesTerm =
      (label.toLowerCase().includes(term.toLowerCase())) || (normalizedLabel.includes(term.toLowerCase()));

    const secondaryTextIncludesTerm = option.secondaryText
      && ((option.secondaryText.toLowerCase().includes(term.toLowerCase()))
      || (normalizedSecondaryText.includes(term.toLowerCase())));

    return labelIncludesTerm || secondaryTextIncludesTerm;
  } else {
    return label.toLowerCase().includes(term.toLowerCase())
      || (option.secondaryText && option.secondaryText.toLowerCase().includes(term.toLowerCase()));
  }
}

function findMatches(options = [], term = '', diacriticsNormalized = true) {
  if (options == []) return [];
  return options.flatMap(o => findOptionMatches(o, term, diacriticsNormalized));
}

function findOptionMatches(option = {}, term = '', diacriticsNormalized = true) {
  if (optionContainsTerm(option, term, diacriticsNormalized)) {
    return option;
  }

  const matchingChildren = findMatches(option.options, term, diacriticsNormalized);
  if (matchingChildren.length > 0) {
    return Object.assign({}, option, {options: matchingChildren});
  }

  return [];
}

/**
 * @param {{
 *   config: !Config,
 *   options: !Array<Option>,
 *   selectedOptions?: !Array<number|string>,
 *   wrapperClassName?: string,
 *   nativeRef?: ?,
 * }} props
 * @return {React.ReactElement}
 */
export function SelectController({
  config,
  options,
  // If selectedOptions is provided, SelectController will use it instead of its own state.
  // If selectedOptions is undefined, SelectController will track selected options in its state.
  selectedOptions,
  wrapperClassName,
  nativeRef,
}) {
  const fullConfig = Config.extendDefaults(config);
  const defaultSelectionValues = getDefaultSelection(fullConfig, options);

  const [selectedOptionsState, setSelectedOptionsState] = React.useState(defaultSelectionValues);
  const [dropdownExpanded, setDropdownExpanded] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  selectedOptions = selectedOptions || selectedOptionsState;

  const optionTree = OptionStateTree.fromOptionsAndSelectionValues(options, selectedOptions);
  const currentOptions = searchTerm === '' ? options : findMatches(
    options, searchTerm, fullConfig.diacriticsNormalized);

  const numOptions = countTotalChildren(options);

  const publishChanges = (optionTree, suppressEvent = false) => {
    const values = optionTree.getSelectedOptionValues();
    fullConfig.onSelect(fullConfig.multiselect ? values : values[0]);
    if (!suppressEvent && nativeRef) {
      // TODO(amullings): Use CustomEvent constructor w/ polyfill
      const event = document.createEvent('CustomEvent');
      event['initCustomEvent']('change', true, false, {'fromSelectController': true});
      nativeRef.current.dispatchEvent(event);
    }
  };

  const onSelectChange = (selectedOptionValues, suppressEvent = false) => {
    setSelectedOptionsState(selectedOptionValues);
    const newOptionTree = OptionStateTree.fromOptionsAndSelectionValues(options, selectedOptionValues);
    setDropdownExpanded(false);
    publishChanges(newOptionTree, suppressEvent);
  };

  let selectAllState;
  const numSelected = selectedOptions?.length;
  if (numSelected === 0) {
    selectAllState = optionStates.UNSELECTED;
  } else if (numSelected === options.length) {
    selectAllState = optionStates.SELECTED;
  } else {
    selectAllState = optionStates.INDETERMINATE;
  }

  const onSelectAll = () => {
    const selected = [];
    options.forEach(o => {
      if (!o.disabled ) {
        selected.push(o.value);
      }
    });
    const optionTree = OptionStateTree.fromOptionsAndSelectionValues(options, selected);
    setSelectedOptionsState(selected);
    selectedOptions = selected;
    publishChanges(optionTree);
  };


  const onSelectNone = () => {
    selectedOptions = [];
    const optionTree = OptionStateTree.fromOptionsAndSelectionValues(options, selectedOptions);
    setSelectedOptionsState(selectedOptions);
    publishChanges(optionTree);
  };

  React.useEffect(() => {
    if (nativeRef) {
      nativeRef.current.addEventListener('select-reset', () => {
        onSelectChange(defaultSelectionValues, true);
      });
    }
  }, [nativeRef]); // eslint-disable-line

  return (
    <SelectRoot
      options={optionTree.applySelectedStateToOptions(currentOptions)}
      allowSearch={fullConfig.enableSearch || numOptions >= 15}
      searchPlaceholder={fullConfig.searchPlaceholder}
      onSearch={term => setSearchTerm(term)}
      onOptionChange={(optionValue, selected) => {
        const newOptionTree = optionTree.newTreeWithOption(optionValue, selected);
        const newSelectedOptions = newOptionTree.getSelectedOptionValues();
        setSelectedOptionsState(newSelectedOptions);
        publishChanges(newOptionTree);
      }}
      onSelectChange={onSelectChange}
      onNativeChange={event => {
        if (event.nativeEvent.detail && event.nativeEvent.detail['fromSelectController']) {
          return;
        }
        onSelectChange([...event.target.selectedOptions].map(o => o.value), true);
      }}
      nativeElemRef={nativeRef}
      label={
        selectLabel(
          getOptionsWithValues(optionTree.getSelectedOptionValues(), options),
          fullConfig,
          options[0])
      }
      dropdownExpanded={dropdownExpanded}
      onToggleDropdown={() => setDropdownExpanded(expanded => !expanded)}
      onCloseDropdown={() => {
        setDropdownExpanded(false);
        setSearchTerm('');
      }}
      searchTerm={searchTerm}
      wrapperClassName={wrapperClassName}
      onSelectAll ={onSelectAll}
      selectAllState = {selectAllState}
      onSelectNone = {onSelectNone}
      {...fullConfig}
    />
  );
}
