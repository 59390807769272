import yext from 'goog:yext';

import {slowRegisterComponentForJsonData} from '/ui/lib/easyreact';

export function ForgotPasswordConfirm(props) {
  return (
    <div id="sent" className="content">
      <h2 className="forgot-heading">{yext.msg('Request Processed')}</h2>
      <p className="center-align">
        {yext.msg('If this username/email address matches an account on file, we have sent you a link to reset your password.')}
      </p>
      <p className="return-msg">
        <a href="login">{yext.msg('Return to Login page')}</a>
      </p>
    </div>);
}

slowRegisterComponentForJsonData('ForgotPasswordConfirm', ForgotPasswordConfirm);
