require('/ui/components/button/button.module.scss.module.css');

export const button = "_button_18kqq_1";
export const smallViewportSupport = "_small-viewport-support_18kqq_1";
export const defaultButton = "_default-button_18kqq_1";
export const primary = "_primary_18kqq_1";
export const warning = "_warning_18kqq_1";
export const ghost = "_ghost_18kqq_1";
export const chromeless = "_chromeless_18kqq_1";
export const small = "_small_18kqq_1";
export const fullWidth = "_full-width_18kqq_1";
export const compactLayout = "_compact-layout_18kqq_1";
export const embeddedButton = "_embedded-button_18kqq_1";
export const manaUiButton = "_mana-ui-button_18kqq_1";
