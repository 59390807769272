// NOTE(vple): This is similar to xhr.js, but a goog.module.
/**
 * @fileoverview XHR helpers.
 *
 * @author Vincent Le (vple@yext.com)
 */
goog.module('yext.utils.ajax');

const EventType = goog.require('goog.net.EventType');
const Uri = goog.require('goog.Uri');
const XhrIo = goog.require('goog.net.XhrIo');
const gEvents = goog.require('goog.events');

/**
 *  Wrapper method for the XhrIo get request
 *
 * @param {string} url
 * @param {!Function} successCallback
 * @param {!Function} failureCallback
 * @param {!Object=} params (optional) a map of parameter names to values to be added
 * to the request
 * @return {XhrIo}
 */
exports.get = function(url, successCallback, failureCallback, params = {}) {
  const xhr = new XhrIo();

  gEvents.listen(xhr, EventType.SUCCESS, e => successCallback(e.target.getResponseJson()));
  gEvents.listen(xhr, EventType.ERROR, e => failureCallback(e.target.getResponseText()));

  for (let [key, value] of Object.entries(params)) {
    if (!value && value !== 0) {
      delete params[key];
    }
  }
  xhr.send(new Uri(url).setQueryData(Uri.QueryData.createFromMap(params)));
  return xhr;
};

/**
 *  Wrapper method for the XhrIo get request, ignoring any responses
 *
 * @param {string} url
 * @param {!Function} successCallback
 * @param {!Function} failureCallback
 * @param {!Object=} params (optional) a map of parameter names to values to be added
 * to the request
 * @return {XhrIo}
 */
exports.getNoResponse = function(url, successCallback, failureCallback, params = {}) {
  const xhr = new XhrIo();

  gEvents.listen(xhr, EventType.SUCCESS, e => successCallback());
  gEvents.listen(xhr, EventType.ERROR, e => failureCallback());

  for (let [key, value] of Object.entries(params)) {
    if (!value && value !== 0) {
      delete params[key];
    }
  }
  xhr.send(new Uri(url).setQueryData(Uri.QueryData.createFromMap(params)));
  return xhr;
};

// NOTE(vple):
// Doesn't seem to play nice with false-y data values.
// I think it's related to this: https://groups.google.com/g/closure-library-discuss/c/OSYDVP_5rKQ
/**
 *  Wrapper method for the XhrIo post request
 *
 * @param {string} url
 * @param {!Function} successCallback
 * @param {!Function} failureCallback
 * @param {!Object} data the data to be posted
 * @param {!Object=} params (optional) a map of parameter names to values to be added
 * to the request
 * @return {XhrIo}
 */
exports.post = function(url, successCallback, failureCallback, data, params = {}) {
  const xhr = new XhrIo();

  // post request may have an empty response
  gEvents.listen(xhr, EventType.SUCCESS, e => {
    if (e.target.getResponseText().length > 0) {
      successCallback(e.target.getResponseJson());
    } else {
      return successCallback();
    }
  });

  gEvents.listen(xhr, EventType.ERROR, e => failureCallback(e.target.getResponseText()));

  for (let [key, value] of Object.entries(params)) {
    if (!value) {
      delete params[key];
    }
  }

  xhr.send(
    new Uri(url).setQueryData(Uri.QueryData.createFromMap(params)),
    'POST', Uri.QueryData.createFromMap(data).toString(),
    {'X-Requested-With': 'XMLHttpRequest'});
  return xhr;
};

/**
 *  Wrapper method for the XhrIo post request, ignoring any responses
 *
 * @param {string} url
 * @param {!Function} successCallback
 * @param {!Function} failureCallback
 * @param {!Object} data the data to be posted
 * @param {!Object=} params (optional) a map of parameter names to values to be added
 * to the request
 * @return {XhrIo}
 */
exports.postNoResponse = function(url, successCallback, failureCallback, data, params = {}) {
  const xhr = new XhrIo();

  gEvents.listen(xhr, EventType.SUCCESS, e => successCallback());

  gEvents.listen(xhr, EventType.ERROR, e => failureCallback());

  for (let [key, value] of Object.entries(params)) {
    if (!value) {
      delete params[key];
    }
  }

  xhr.send(
    new Uri(url).setQueryData(Uri.QueryData.createFromMap(params)),
    'POST', Uri.QueryData.createFromMap(data).toString(),
    {'X-Requested-With': 'XMLHttpRequest'});
  return xhr;
};
