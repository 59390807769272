
/**
 * Opens the Osano cookie preferences drawer, in the same manner as clicking the
 * Osano default cookie widget would.
 * https://docs.osano.com/hiding-the-cookie-widget
 */
export function openCookieDrawer() {
  window['Osano']['cm']['showDrawer']('osano-cm-dom-info-dialog-open');
}

// Uncomment below if needed for testing.
// window['openCookieDrawer'] = openCookieDrawer;
