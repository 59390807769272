// @ts-nocheck
goog.declareModuleId('yext.ui.components.DropdownTrigger');

import events from 'goog:goog.events';
import EventType from 'goog:goog.events.EventType';
import Box from 'goog:goog.math.Box';
import AnchoredPosition from 'goog:goog.positioning.AnchoredPosition';
import AnchoredViewportPosition from 'goog:goog.positioning.AnchoredViewportPosition';
import Corner from 'goog:goog.positioning.Corner';

import {hasManaUITheme} from '/ui/design/hasManaUITheme';
import {debounce} from '/ui/lib/debounce';
import {yext} from '/ui/lib/msg';
import {newUid} from '/ui/lib/uid';

import * as styles from '/ui/components/dropdowntrigger/dropdowntrigger.module.scss';

const REPOSITION_DEBOUNCE_MS = 200;
const FOCUSABLE_SELECTOR = '[tabindex], button:not(:disabled), a[href], input:not(:disabled), select:not(:disabled), textarea:not(:disabled), details';

// If we remove the margins from .ui-dropdown-container, then this should be:
// const DROPDOWN_MARGIN = new Box(+styles.dropdownMargin, 0, +styles.dropdownMargin, 0);
const DROPDOWN_MARGIN = new Box(0, 0, 2 * +styles.dropdownMargin, 0);
const CONTEXTUAL_DROPDOWN_MARGIN = new Box(0, -12, 2 * +styles.dropdownMargin, -12);

/** @record */
export function TriggerProps() {}
/** @type {!Object} */
TriggerProps.prototype.buttonRef;
/** @type {boolean} */
TriggerProps.prototype.dropdownExpanded;
/** @type {React.ReactNode|undefined} */
TriggerProps.prototype.label;
/**
 * @type {{
 *   'aria-controls': string,
 *   'aria-expanded': boolean,
 *   'aria-haspopup': string,
 *   className: string,
 *   disabled: (boolean|undefined),
 *   onClick: function(Event): ?,
 *   onKeyDown: function(Event): ?,
 *   tid: (string|undefined),
 *   'data-pendo': (string|undefined),
 * }}
 */
TriggerProps.prototype.attributes;

/** @typedef {React.Component<TriggerProps> | !function(TriggerProps): (React.ReactElement|null) | undefined} */
export let TriggerComponent;

/**
 * @typedef {{
 *   alignMenu?: string,
 *   ariaLabel?: string,
 *   ariaRole?: string,
 *   buttonRef?: (React.RefObject<HTMLElement>|null),
 *   children?: React.ReactNode,
 *   trigger?: TriggerComponent,
 *   chromeless?: boolean,
 *   disabled?: boolean,
 *   dropdownExpanded: boolean,
 *   focusOutDetection?: string,
 *   isContextual?: boolean,
 *   label?: React.ReactNode,
 *   onCloseDropdown: (function(): ?),
 *   onToggleDropdown: (function(): ?),
 *   primary?: boolean,
 *   ghost?: boolean,
 *   tid?: string,
 *   pendoId?: string,
 *   triggerClass?: string,
 *   allowContainerOverflow?: boolean,
 *   wrapperClassName?: string,
 *   ref?: React.RefObject<HTMLElement>,
 * }}
 */
export let DropdownProps;

/**
 * @param {boolean|function(): boolean} initiallyOpen
 * @return {{
 *   dropdownExpanded: boolean,
 *   onCloseDropdown: !function(),
 *   onToggleDropdown: !function(),
 * }}
 */
export function useDropdownTriggerState(initiallyOpen) {
  const [dropdownExpanded, setExpanded] = React.useState(initiallyOpen);
  return {
    dropdownExpanded,
    onCloseDropdown: () => setExpanded(false),
    onToggleDropdown: () => setExpanded(expanded => !expanded),
  };
}

/** @extends {React.Component<DropdownProps>} */
class DropdownTriggerImpl extends React.Component {
  /**
   * @param {DropdownProps} props
   */
  constructor(props) {
    super(props);

    this.id = newUid();

    this.handleKeyOnButton = this.handleKeyOnButton.bind(this);
    this.handleKeyOnContainer = this.handleKeyOnContainer.bind(this);
    this.reposition = this.reposition.bind(this);
    this.DefaultButton = this.DefaultButton.bind(this);
    this.debouncedReposition = debounce(this.reposition, REPOSITION_DEBOUNCE_MS);

    /** @type {!React.RefObject<HTMLElement>} */
    this.buttonRef = React.createRef();
    /** @type {!React.RefObject<HTMLDivElement>} */
    this.containerRef = React.createRef();
    /** @type {!React.RefObject<HTMLDivElement>} */
    this.wrapperRef = React.createRef();
    this.removeFocusOutHandler = null;
  }

  /**
   * @returns {!React.RefObject<HTMLElement>}
   */
  getButtonRef() {
    return this.props.buttonRef || this.buttonRef;
  }

  handleKeyOnButton(event) {
    if (['ArrowDown', 'ArrowUp', 'Down'/* IE11 */, 'Up'/* IE11 */]
      .includes(event.key)) {
      event.preventDefault();
      this.props.onToggleDropdown();
    }
  }

  handleKeyOnContainer(event) {
    // NOTE(amullings): Input elements (like the search field) seem to do their
    // own handling of the Escape key that stops it from being visible to other
    // elements, even when listening to events in capture mode. Fortunately,
    // this default behavior `blur`s the input and doesn't move focus to another
    // element, thus triggering our focusout handler.
    // Escape key events don't get sent to this handler in IE11 (which we don't support anyways)
    if ('Escape' === event.key) {
      event.preventDefault();
      this.props.onCloseDropdown();
      this.getButtonRef().current.focus();
    }
  }

  componentDidMount() {
    if (!this.props.allowContainerOverflow) {
      if (this.props.focusOutDetection === 'strong') {
        this.removeFocusOutHandler = onFocusOutside(
          /** @type {HTMLElement} */ (this.containerRef.current.parentNode),
          this.props.onCloseDropdown,
          this.getButtonRef().current);
      } else {
        this.removeFocusOutHandler = onClickOutside(
          [this.wrapperRef, this.containerRef],
          this.props.onCloseDropdown);
      }
    }
    this.reposition();
    events.listen(window, EventType.RESIZE, this.debouncedReposition);
    // Scroll events don't bubble, so use capture mode to detect scrolls in nested containers
    events.listen(window, EventType.SCROLL, this.debouncedReposition, {capture: true});
  }

  componentWillUnmount() {
    if (this.removeFocusOutHandler) {
      this.removeFocusOutHandler();
    }
    events.unlisten(window, EventType.RESIZE, this.debouncedReposition);
    events.unlisten(window, EventType.SCROLL, this.debouncedReposition);
  }

  componentDidUpdate(prevProps) {
    this.reposition();

    if (!prevProps.dropdownExpanded && this.props.dropdownExpanded && this.containerRef.current) {
      const firstChild = /** @type {?HTMLElement} */ (this.containerRef.current.firstElementChild);
      // Attempt to move focus to the first element in the container, otherwise
      // fall back to the container
      if (firstChild && firstChild.matches(FOCUSABLE_SELECTOR)) {
        firstChild.focus();
      } else {
        this.containerRef.current.focus();
      }
    }
  }

  reposition() {
    if (this.props.dropdownExpanded && this.containerRef.current) {
      const buttonCorner = this.props.alignMenu === 'right' ? Corner.BOTTOM_RIGHT : Corner.BOTTOM_LEFT;
      const corner = this.props.alignMenu === 'right' ? Corner.TOP_RIGHT : Corner.TOP_LEFT;
      const margin = this.props.isContextual ? CONTEXTUAL_DROPDOWN_MARGIN : DROPDOWN_MARGIN;

      // AnchoredPosition won't flip vertical positions, while
      // AnchoredViewportPosition will. We currently don't have styling for
      // vertically flipped contextual menus.
      const position = this.props.isContextual
        ? new AnchoredPosition(this.getButtonRef().current, buttonCorner)
        : new AnchoredViewportPosition(this.getButtonRef().current, buttonCorner, true);
      position.reposition(this.containerRef.current, corner, margin);
    }
  }

  // NOTE(amullings): It would be cleaner if this functional component didn't reference
  // DropdownTriggerImpl instance fields, but creating a new component function for every render
  // call breaks some assumptions that our tests (and potentially other code) make about the trigger
  // element remaining the same across re-renders. Plus I suppose this is more efficient anyway.
  DefaultButton({
    label,
    buttonRef,
    attributes,
  }) {
    const {
      className,
      ...additionalAttributes
    } = attributes;
    let buttonClassList = [
      className,
      styles.trigger,
      styles.smallViewSupport,
      'ui-dropdown-trigger unittest-dropdown-trigger-button',
    ];
    if (this.props.triggerClass) {
      buttonClassList.push(this.props.triggerClass);
    }
    buttonClassList.push(this.props.isContextual ? 'ui-dropdown-context' : 'btn-default');

    if (this.props.chromeless) {
      buttonClassList = buttonClassList.concat(
        ['bg-transparent', 'shadow-none', 'color-gray7', `${styles.chromeless}`]);
    }

    if (hasManaUITheme()) {
      buttonClassList.push(styles.neutral);
    }

    if (this.props.primary) {
      buttonClassList.push(styles.primary);
    }

    if (this.props.ghost) {
      buttonClassList.push(styles.ghost);
    }

    return (
      <button
        {...additionalAttributes}
        ref={buttonRef}
        type="button"
        data-pendo={this.props.pendoId}
        className={buttonClassList.join(' ')}
        aria-label={label ? undefined : (this.props.ariaLabel ? this.props.ariaLabel : yext.msg('Show additional options'))}
      >
        {!this.props.isContextual
          && <span className={'ui-dropdown__label unittest-dropdown-trigger-label ' + styles.fontSizing}>{label}</span>}
      </button>
    );
  }

  /**
   * @param {TriggerComponent} TriggerComp
   * @returns {React.ReactElement}
   */
  renderTrigger(TriggerComp) {
    const Comp = /** @type {function(TriggerProps): React.ReactElement} */ (TriggerComp);
    return (
      <Comp
        buttonRef={this.getButtonRef()}
        label={this.props.label}
        dropdownExpanded={this.props.dropdownExpanded}
        attributes={{
          // NOTE(amulllings): Ideally classes on the trigger component shouldn't matter for dropdown
          // functionality
          'className': this.props.dropdownExpanded ? '' : 'ui-dropdown-collapsed ' + styles.collapsed,
          'onClick': this.props.onToggleDropdown,
          'onKeyDown': this.handleKeyOnButton,
          'aria-haspopup': this.props.ariaRole || 'true',
          'aria-controls': this.id,
          'aria-expanded': this.props.dropdownExpanded,
          'disabled': this.props.disabled,
          'tid': 'dropdown-trigger',
          'data-pendo': this.props.pendoId,
        }}
      ></Comp>
    );
  }

  render() {
    const containerClassList = ['ui-dropdown-container unittest-dropdown-trigger-container'];
    containerClassList.push(this.props.isContextual ? 'ui-dropdown-container--contextual' : '');
    containerClassList.push(this.props.alignMenu === 'right' ? 'ui-dropdown-container--align-right' : '');
    containerClassList.push(this.props.dropdownExpanded ? '' : styles.containerCollapsed);

    /** @type {!Array<string|undefined>} */
    const wrapperClassList = ['ui-dropdown-wrapper'];
    wrapperClassList.push(this.props.wrapperClassName);
    const wrapperClass = wrapperClassList.join(' ');

    const trigger = this.renderTrigger(this.props.trigger || this.DefaultButton);

    const container =
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        ref={this.containerRef}
        id={this.id}
        className={containerClassList.join(' ')}
        onKeyDown={this.handleKeyOnContainer}
        tabIndex={-1}
      >
        {this.props.children}
      </div>;

    if (this.props.allowContainerOverflow) {
      return (
        <Wrapper
          wrapperClass={wrapperClass}
          wrapperRef={this.wrapperRef}
          containerRef={this.containerRef}
          buttonRef={this.getButtonRef()}
          trigger={trigger}
          onCloseDropdown={this.props.onCloseDropdown}
          tid={this.props.tid}
        >
          {container}
        </Wrapper>
      );
    }

    return (
      <div
        className={wrapperClass}
        ref={this.wrapperRef}
        tid={this.props.tid}
      >
        {trigger}
        {container}
      </div>);
  }
}

/**
 * @param {{
 *   wrapperClass: string,
 *   wrapperRef: !React.RefObject<HTMLDivElement>,
 *   containerRef: !React.RefObject<HTMLDivElement>,
 *   buttonRef: !React.RefObject<HTMLElement>,
 *   trigger: React.ReactNode,
 *   onCloseDropdown: function(): ?,
 *   tid: (string|undefined),
 *   children: (React.ReactNode|undefined),
 * }} props
 */
function Wrapper({
  wrapperClass,
  wrapperRef,
  containerRef,
  buttonRef,
  trigger,
  onCloseDropdown,
  tid,
  children,
}) {
  const {onBlur, onFocus} = useFocusOutside(onCloseDropdown, [wrapperRef, containerRef]);

  return (
    <div
      className={wrapperClass}
      ref={wrapperRef}
      tid={tid}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      {trigger}
      <DropdownPortal>
        <FocusWormhole to={buttonRef} />
        {children}
        {/*
          * NOTE(amullings): Ideally this would give focus to the next
          * element in the tab order after the trigger, but calculating
          * this is non-trivial.
          */}
        <FocusWormhole to={buttonRef} />
      </DropdownPortal>
    </div>
  );
}

/**
 * NOTE(amullings): null is only included here because out React.createElement
 * types are messed up
 * @param {{
 *   children: (React.ReactNode|undefined),
 * }} props
 */
function DropdownPortal(props) {
  // Portals aren't supported by the server renderer, so wait until the
  // component is mounted to create one.
  const [element, setElement] = React.useState(null);
  React.useEffect(() => {
    const el = document.createElement('div');
    el.classList.add(styles.portal);
    document.body.appendChild(el);
    setElement(el);
    return () => {
      document.body.removeChild(el);
    };
  }, []);
  return element && ReactDOM.createPortal(props && props.children, element);
}

/**
 * @param {{
 *   to: React.RefObject<HTMLElement>,
 * }} props
 * @returns {React.ReactElement}
 */
function FocusWormhole({
  to,
}) {
  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
  return <div tabIndex={0} onFocus={() => to.current && to.current.focus()} />;
}

/**
 * All-in-one function that implements the pattern recommended in
 * https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
 * Avoids using the non-standard React implementations of onFocus and onBlur,
 * since their main benefit is working across portal boundaries, and the
 * behavior may change between React 16 and 17.
 * TODO(amullings): turn into a higher-order component?
 *
 * @param {HTMLElement} element - Element to check click target against.
 * @param {function(): ?} callback - Callback function.
 * @param {HTMLElement} excluded - Element to exclude from the check.
 * @return {function(): void} A function to remove event handlers
 */
function onFocusOutside(element, callback, excluded) {
  /** @type {number|undefined} */
  let timeoutId;
  const cancelCallback = () => window.clearTimeout(timeoutId);
  const enqueueCallback = () => {
    cancelCallback();
    timeoutId = window.setTimeout(callback);
  };
  const deferCancelCallback = () => window.setTimeout(cancelCallback);
  element.addEventListener('focusout', enqueueCallback);
  element.addEventListener('focusin', cancelCallback);
  if (excluded) {
    // mousedown gets triggered before focusout
    excluded.addEventListener('mousedown', deferCancelCallback);
  }
  return function remove() {
    cancelCallback();
    element.removeEventListener('focusout', enqueueCallback);
    element.removeEventListener('focusin', cancelCallback);
    if (excluded) {
      excluded.removeEventListener('mousedown', deferCancelCallback);
    }
  };
}

/**
 * Checks the target for clicks on <body>.
 * If elements don't contain the target, execute the callback.
 *
 * @param {!Array<!React.RefObject<HTMLElement>>} refs - Elements to check the click target against.
 * @param {function(): ?} callback - Callback function.
 * @return {function(): void} A function to remove event handlers
 */
function onClickOutside(refs, callback) {
  /** @type {function(Event): void} */
  const handler = e => {
    const composedPath = e.composedPath();
    if (!refs.some(ref => ref.current && composedPath.includes(ref.current))) {
      callback();
    }
  };
  document.body.addEventListener('click', handler);
  return function remove() {
    document.body.removeEventListener('click', handler);
  };
}

/**
 * Combines focus and click detection to trigger a callback when the user moves
 * focus outside of a container. Has the keyboard detection of onFocusOutside
 * but doesn't activate when keyboard focus is moved to document.body, instead
 * relying on click detection to handle clicking outside the container.
 *
 * @param {function(): ?} callback
 * @param {!Array<!React.RefObject<HTMLElement>>} containers - Elements within which to check for clicks
 * @returns {{
 *   onBlur: function(),
 *   onFocus: function(),
 * }}
 */
function useFocusOutside(callback, containers) {
  /** @type {!React.MutableRefObject<number|undefined>} */
  const timeoutId = React.useRef();
  const cancelCallback = () => window.clearTimeout(timeoutId.current);
  const enqueueCallback = () => {
    cancelCallback();
    timeoutId.current = window.setTimeout(() => {
      // Focus being on the body generally occurs in one of two situations:
      // a) The user clicks on a non-interactive element
      // b) The element that had keyboard focus was removed from the DOM
      // c) A button has been clicked and the browser is allergic to showing
      //    keyboard focus (e.g. Safari)
      // None of these cases represent the user actually moving focus outside
      // the element via keyboard, so don't trigger the callback.
      if (document.activeElement == document.body) {
        return;
      }
      callback();
    });
  };
  React.useEffect(() => function cleanup() {
    window.clearTimeout(timeoutId.current);
  }, []);

  // NOTE(amullings): This could be changed to use a global click handler that
  // enqueues the callback and a return a click handler that cancels it, which
  // would take advantage of the fact that React synthetic events bubble across
  // portal boundaries and remove the need to pass in a list of containers.
  //
  // Could also only add the document click listener when the dropdown is open,
  // to avoid having a billion click handlers at a time
  React.useEffect(() => {
    return onClickOutside(containers, callback);
  });
  return {
    onBlur: enqueueCallback,
    onFocus: cancelCallback,
  };
}

export const DropdownTrigger = /** @type {function(!DropdownProps): React.ReactElement} */ (
  React.forwardRef(
    /** @suppress {checkTypes} */
    function ButtonRef(props, ref) {
      return <DropdownTriggerImpl {...props} buttonRef={ref} />;
    },
  )
);
