goog.declareModuleId('yext.ui.components.select.standard.MultiSelectOption');

import {SelectOptionLabel} from '/ui/components/select/standard/selectoptionlabel';

export class MultiSelectOption extends React.Component {
  constructor(props) {
    super(props);
    this.checkbox = React.createRef();
  }

  componentDidMount() {
    this.checkbox.current.indeterminate = this.props.indeterminate;
  }

  componentDidUpdate() {
    this.checkbox.current.indeterminate = this.props.indeterminate;
  }

  render() {
    const listItemClasses = ['ui-multiselect-option js-select-option'];
    const labelClasses = ['ui-select-row js-select-target'];

    if (this.props.option.disabled) {
      listItemClasses.push('ui-select-option-disabled js-select-option-disabled');
    }
    if (this.props.additionalCssClass) {
      labelClasses.push(this.props.additionalCssClass);
    }

    return (
      <li
        className={listItemClasses.join(' ')}
        {...this.props.ariaAttributes}
        // use string keys to prevent advanced compilation
        {...{'aria-selected': this.props.checked}}
      >
        {this.props.children}
        <label
          className={labelClasses.join(' ')}
        >
          <input
            tabIndex="-1"
            type="checkbox"
            disabled={this.props.option.disabled}
            className="ui-multiselect-checkbox"
            ref={this.checkbox}
            data-pendo={this.props.option.pendoId}
            onChange={e => this.props.onOptionChange(this.props.option.value, e.target.checked)}
            checked={this.props.checked} />
          <SelectOptionLabel option={this.props.option} />
        </label>
      </li>);
  }
}
