/**
 * @fileoverview Internationalization methods for user-facing text
 */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */
/* eslint-disable prefer-spread */
/* eslint-disable no-redeclare */

import languagePluralizers from '/ui/lib/languagepluralizers';

/**
 * Get the translated plural form of the message with the given id and
 * version index and format it
 * @param {string} ctxt the context of the message to translate
 * @param {string} msgId the id of the message to translate
 * @param {number} index the version of the message to use
 * @param {string} defaultMsg the default value for the message
 * @param {...*} var_args arguments to format the message with
 *  or a single object mapping message parameters to values
 * @return {string} The translated message
 */
const getFormattedMessage = function(ctxt, msgId, index, defaultMsg, var_args) {
  var msg;
  if (window.pseudoLocalizationParams) {
    msg = pseudoLocalize(defaultMsg);
  } else {
    var versions = null;
    if (ctxt != null && window.contextMap) {
      var m = window.contextMap[ctxt];
      if (m) {
        versions = m[msgId];
      }
    } else if (ctxt == null && window.translationsMap) {
      versions = window.translationsMap[msgId];
    }
    msg = (!versions || index >= versions.length) ? defaultMsg : versions[index];
  }

  var args = Array.prototype.slice.call(arguments, 4);

  // soy messages are stored with named parameters while js ones are numbered
  if (args.length == 1 && typeof args[0] == 'object') {
    // if there's only one arg and it's an object
    // then we're using a parameterObject of a parameterArray
    var parameterObject = args[0];
    // weirdly non-plural messages prefix their arguments with a $ but plural messages don't
    return msg.replace(/{\$?([^}]+)}/g, function(match, key) {
      return typeof parameterObject[key] != 'undefined'
        ? parameterObject[key]
        : match
      ;
    });
  } else {
    // Format the message
    return msg.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
      ;
    });
  }
};

/**
 * Pseudolocalize the given msg given the current window.pseudoLocalizationParams
 * @param {string} msg the context of the message to pseudolocalize
 * @return {string} The pseudolocalized message
 */
const pseudoLocalize = function(msg) {
  // TODO(sgutz) Inject bracket symbols and substitution alphabet from controller?
  if (!window.pseudoLocalizationParams) {
    return msg;
  }

  var result = '';
  if (window.pseudoLocalizationParams['bracket']) {
    result += '||';
  }

  var paramRegex = /\{\d\}/;
  // Note(sgutz): This will break if '>' appears inside a string literal in the tag
  var htmlTagRegex = /<[^>]*>/;
  var htmlEntityRegex = /&#\d{1,3};|&[A-Za-z]+;/;
  var ignoreRegexString = [paramRegex.source, htmlTagRegex.source, htmlEntityRegex.source].join('|');

  var charCount = 0.0;
  var lengthenPercent = getPseudolocalizationLengthenPercent(msg.replace(ignoreRegexString, '').length);

  // The grouping preserves the delimiter
  var parts = msg.split(new RegExp('(' + ignoreRegexString + ')'));
  for (var j = 0; j < parts.length; j++) {
    var part = parts[j];
    // Leave message format parameters and HTML tags/entities
    if (part.match(new RegExp(ignoreRegexString))) {
      result += part;
      continue;
    }

    for (var i = 0; i < part.length; i++) {
      var s = String.fromCharCode(getPseudolocalizationSubstitution(part.charCodeAt(i)));
      result += s;
      if (lengthenPercent > 0) {
        charCount += 1;
        while (charCount > 100.0 / lengthenPercent) {
          result += s;
          charCount -= 100.0 / lengthenPercent;
        }
      }
    }
  }

  if (window.pseudoLocalizationParams['bracket']) {
    result += '||';
  }

  return result;
};

/**
 * Determine the amount to lengthen a PseudoLocalized string
 * @param {number} msgLength the length of the message to lengthen
 * @return {number} The percent to lengthen the string
 */
const getPseudolocalizationLengthenPercent = function(msgLength) {
  if (!window.pseudoLocalizationParams['lengthen']) {
    return 0;
  } else if (msgLength <= 4) {
    return 100;
  } else if (msgLength <= 10) {
    return 80;
  } else if (msgLength <= 20) {
    return 60;
  } else if (msgLength <= 30) {
    return 40;
  } else if (msgLength <= 50) {
    return 20;
  } else {
    return 10;
  }
};

/**
 * Substitute an alternate character for c as indicated by
 * window.pseudoLocalizationParams
 * @param {number} c the character to substitute for
 * @return {number} The substituted character
 */
const getPseudolocalizationSubstitution = function(c) {
  if (!window.pseudoLocalizationParams['substitute'] || !window.pseudoLocalizationParams['upper']
      || !window.pseudoLocalizationParams['lower']) {
    return c;
  }

  if (c >= 'A'.charCodeAt(0) && c <= 'Z'.charCodeAt(0)) {
    return window.pseudoLocalizationParams['upper'].charCodeAt(c - 'A'.charCodeAt(0));
  }
  if (c >= 'a'.charCodeAt(0) && c <= 'z'.charCodeAt(0)) {
    return window.pseudoLocalizationParams['lower'].charCodeAt(c - 'a'.charCodeAt(0));
  }
  return c;
};

/**
 * Get the translated form of the message with the given id.
 * @param {string} msgId the id of the message to translate
 * @param {...*} [var_args] arguments to format the message with
 * @return {string} The translated message
 */
const msg = function(msgId, var_args) {
  // Copy the arguments array
  var args = Array.prototype.slice.call(arguments, 0);
  // @ts-ignore Maybe we'll refactor this to use spread args at some point
  return msgc.apply(null, [null].concat(args));
};

/**
 * Get the translated form of the message with the given context and id.
 * @param {string} ctxt the context of the message to translate
 * @param {string} msgId the id of the message to translate
 * @param {...*} [var_args] arguments to format the message with
 * @return {string} The translated message
 */
const msgc = function(ctxt, msgId, var_args) {
  var args = Array.prototype.slice.call(arguments, 2);
  // @ts-ignore Maybe we'll refactor this to use spread args at some point
  return getFormattedMessage.apply(null, [ctxt, msgId, 0, msgId].concat(args));
};

/**
 * Get the translated plural form of the message with the given ids and
 * variable number.
 * @param {string} msgId the id of the message to translate
 * @param {string} pluralMsgId the plural id of the message to translate
 * @param {number} n the number to use in pluralization
 * @param {...*} [var_args] arguments to format the message with
 *  or a single object mapping message parameters to values
 * @return {string} The translated message
 */
const msgn = function(msgId, pluralMsgId, n, var_args) {
  // Copy the arguments array
  var args = Array.prototype.slice.call(arguments, 0);
  // @ts-ignore Maybe we'll refactor this to use spread args at some point
  return msgcn.apply(null, [null].concat(args));
};

/**
 * Get the translated plural form of the message with the given ids and
 * variable number.
 * @param {string} ctxt the context of the message to translate
 * @param {string} msgId the id of the message to translate
 * @param {string} pluralMsgId the plural id of the message to translate
 * @param {number} n the number to use in pluralization
 * @param {...*} [var_args] arguments to format the message with
 *  or a single object mapping message parameters to values
 * @return {string} The translated message
 */
const msgcn = function(ctxt, msgId, pluralMsgId, n, var_args) {
  const currentLanguage = window.currentLanguage || 'en';
  const pluralKeywordToIndex = window.pluralKeywordToIndex || {one: 0, other: 1};
  var defaultMsg = n == 1 ? msgId : pluralMsgId;
  var pluralizer = languagePluralizers[currentLanguage];
  var index = pluralKeywordToIndex[pluralizer(n)];
  if (index === undefined) {
    // TODO(sgutz) Log an error
    index = 0;
  }

  // if we are using a parameters object (soy) we don't need n
  if (var_args && typeof var_args == 'object') {
    var args = [var_args];
  } else {
    // Keep n as an argument
    var args = Array.prototype.slice.call(arguments, 3);
  }
  // @ts-ignore Maybe we'll refactor this to use spread args at some point
  return getFormattedMessage.apply(null, [ctxt, msgId, index, defaultMsg].concat(args));
};

/**
 * Wrapper for msg methods. Our message parser specifically looks for yext.msg,
 * so it is necessary to use this wrapper method to maintain compatibility with
 * our tooling.
 */
export const yext = {
  msg,
  msgc,
  msgn,
  msgcn,
};
