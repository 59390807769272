// @ts-nocheck
goog.declareModuleId('yext.ui.lib.errors');

const Sentry = /** @type {SentryBrowser} */ (require('@sentry/browser'));

const {Hub, BrowserClient, defaultStackParser, defaultIntegrations, makeFetchTransport} = Sentry;
const auroraFallbackDsn = 'https://6510e46218f74b1698f658a157f0b785@sentry.yext.use4a.devops-o2cwhite.net/21';
const auroraDsn = window['sentryDSNs'] && window['sentryDSNs']['aurora'] || auroraFallbackDsn;
/**
 * Sends the given error object to Sentry.
 * @param {Error} error the response from a fetch call
 */
function sendSentryError(error) {
  Sentry.captureException(error);
}

function sendAuroraSentryError(error) {
  try {
    const hub = new Hub(new BrowserClient({
      dsn: auroraDsn,
      environment: window['sentryEnv'] || 'unknown',
      release: window['sentryRelease'] || 'unknown',
      transport: makeFetchTransport,
      stackParser: defaultStackParser,
      integrations: defaultIntegrations,
      beforeSend: (event, hint) => {
        if (event === null) {
          return event;
        }

        if (!event['tags']) {
          event['tags'] = {};
        }

        event['tags']['data_center'] = window['sentryDataCenter'];
        event['tags']['partition'] = window['sentryPartition'];
        return event;
      },
    }));

    // SEND ERROR TO SENTRY
    hub.run(() => hub.captureException(error));
  } catch {
    console.error('Error in sendAuroraSentryError:\n', error);
  }
}

/**
 * Logs an error according to the environment and params.
 * In Test environments (Sentry is uninitialized), reports the provided
 * error to console.
 * In other environments (Sentry is initialized), sends the event to sentry.
 *
 * This helper intentionally has no support for throwing an error. If a runtime
 * error should be thrown, calling throw SomeError(...) directly is preferred.
 *
 * @param {string | Error} error An error string or object to report
 * @param {boolean=} toTeamAurora Whether the error should be reported to team Aurora (true)
 */
export function logError(error, toTeamAurora=false) {
  if (typeof error === 'string') {
    error = new Error(error);
  }
  const isTest = window['sentryIsTest'];
  if (isTest) {
    console.error(error);
  } else if (!toTeamAurora) {
    sendSentryError(error);
  } else { // toTeamAurora
    sendAuroraSentryError(error);
  }
}
