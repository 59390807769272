require('/ui/components/select/standard/standard.module.scss.module.css');

export const selectLabelContainer = "_select-label-container_1wyow_1";
export const selectLabel = "_select-label_1wyow_1";
export const smallViewportSupport = "_small-viewport-support_1wyow_1";
export const selectAll = "_select-all_1wyow_1";
export const selectOptionIcon = "_select-option-icon_1wyow_1";
export const uiSelectOptionDisabled = "_ui-select-option-disabled_1wyow_1";
export const selectSecondaryText = "_select-secondary-text_1wyow_1";
export const uiMultiselectOption = "_ui-multiselect-option_1wyow_1";
export const uiSelectRow = "_ui-select-row_1wyow_1";
