import Uri from 'goog:goog.Uri';

import ajax from 'goog:yext.utils.ajax';

import GithubIcon from '/src/com/yext/platform/users/public/images/github_icon.svg';
import GoogleIcon from '/src/com/yext/platform/users/public/images/google_icon.svg';

export function OauthButtons(props) {
  const [showGoogleButton, setShowGoogleButton] = React.useState(true);
  React.useEffect(() => {
    const googleLoginButton = document.getElementById('custom-google-signup-btn');
    if (googleLoginButton) {
      try {
        googleOAuthInit(googleLoginButton);
      } catch (e) {
        setShowGoogleButton(false);
        console.error(e);
      }
    }
  }, []);

  return (
    <>
      {showGoogleButton
      && <button type="button" id="custom-google-signup-btn" className="users__login-oauth">
        <GoogleIcon />
        <span>Google</span>
      </button>}
      <button
        type="button"
        id="custom-github-signup-btn"
        className="users__login-oauth"
        onClick={e => onGithubOAuth(props['authenticityToken'])}
      >
        <div className="js-github-authenticity-token"></div>
        <input type="hidden" id="githubOAuthClientId" value={props['githubOAuthClientId']} />
        <GithubIcon />
        <span>Github</span>
      </button>
    </>);
}


/**
 * Initialization for Google OAuth button on login page.
 * @param {!HTMLElement|Element} googleLoginButton
 * @suppress {undefinedVars|checkTypes} gapi is added as a window variable via another script
 */
export function googleOAuthInit(googleLoginButton) {
  gapi.load('auth2', () => {
    const clientId = document.querySelector('meta[name="google-signin-client_id"]')['content'];
    const auth2Google = gapi.auth2.init({
      client_id: clientId,
      cookiepolicy: 'single_host_origin',
    });

    const onGoogleSignIn = function(googleUser) {
      const id_token = googleUser.getAuthResponse().id_token;
      const data = {
        b: document.getElementsByClassName('js-business-id')[0]['value'],
        c: document.getElementsByClassName('js-redirect-url')[0]['value'],
      };
      if (id_token) {
        data['googleUserToken'] = id_token;
      }

      ajax.post(
        '/users/oauth/loginWithGoogle',
        /* success */ response => {
          window.location.href = response['redirect'];
        },
        /* failure */ response => {
          const ajaxErrorElement = document.querySelector('.js-ajax-error');
          ajaxErrorElement.id = 'error';
          if (response) {
            ajaxErrorElement.textContent = response;
          } else {
            ajaxErrorElement.textContent = yext.msg('Something went wrong. Please try again later.');
          }
        },
        data,
      );
    };

    const attachCustomGoogleButtonHandler = function() {
      auth2Google.attachClickHandler(
        googleLoginButton,
        {scope: 'profile email'},
        onGoogleSignIn,
      );
    };

    auth2Google.then(
      /* success */ attachCustomGoogleButtonHandler,
      /* failure */ err => {
        googleLoginButton.onclick = () => {
          const ajaxErrorElement = document.querySelector('.js-ajax-error');
          ajaxErrorElement.id = 'error';
          ajaxErrorElement.textContent = yext.msg(
            'Please enable third-party cookies or ' +
            'disable private browsing to log in with Google');
        };
      });
  });
}

/**
 * Callback triggered when the GitHub OAuth login button is triggered
 * @param {string} authenticityToken session authenticity token
 */
export function onGithubOAuth(authenticityToken) {
  const params = {};
  params['client_id'] = document.getElementById('githubOAuthClientId')['value'];
  params['scope'] = 'user:email';
  params['state'] = authenticityToken;
  const signupCallbackUrl = window.location.protocol + '//' + window.location.hostname +
    '/users/oauth/loginWithGithubCallback';
  params['redirect_uri'] = signupCallbackUrl;
  const gitHubAuthorizationUrl = new Uri('https://github.com/login/oauth/authorize')
    .setQueryData(Uri.QueryData.createFromMap(params));
  window.location.href = gitHubAuthorizationUrl;
}
