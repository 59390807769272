/**
* @fileoverview Option selected state tree data model
*/
goog.declareModuleId('yext.ui.components.select.model.OptionStateTree');

import {Option} from '/ui/components/select/model/option';
import {optionStates} from '/ui/components/select/model/optionstates';

/**
 * @param {!Option} option
 * @param {?Object} valueToSelected
 */
function calculateValueToSelected(option, valueToSelected) {
  if (option.options) {
    option.options.forEach(o => calculateValueToSelected(o, valueToSelected));
  }
  valueToSelected[option.value] = option.selected;
}

/**
 * @param {!Object} valueToSelected
 * @param {!Option} option
 * @return {!Object}
 */
function updateOptionWithSelectedState(valueToSelected, option) {
  if (option.options && option.options.length) {
    // Object assign does not include the prototype
    const clone = new Option(option);
    clone.selected = valueToSelected[option.value];
    clone.options = option.options.map(option => {
      return updateOptionWithSelectedState(valueToSelected, option);
    });
    return clone;
  } else {
    const clone = new Option(option);
    clone.selected = valueToSelected[option.value];
    return clone;
  }
}

export class OptionStateTree {
  constructor(optionStates) {
    this.options = optionStates;
  }

  static fromOptionsAndSelectionValues(options, selectedOptionValues) {
    const tree = [];
    const addOptionToTree = (treeNodes, option, parentSelected) => {
      let selected;
      if (typeof selectedOptionValues === 'string') {
        selected = parentSelected || selectedOptionValues === option.value;
      } else {
        selected = parentSelected || selectedOptionValues.includes(option.value);
      }
      const node = {
        value: option.value,
        selected,
        options: [],
      };
      treeNodes.push(node);
      if (option.options) {
        option.options.forEach(o => addOptionToTree(node.options, o, selected));
      }
    };

    options.forEach(o => addOptionToTree(tree, o, false));
    return new OptionStateTree(tree);
  }

  /**
   * @returns {!Array<string>}
   */
  getSelectedOptionValues() {
    const selectedOptions = [];
    const getSelectedOptionsInSubtree = root => {
      if (root.selected) {
        selectedOptions.push(root.value);
      }
      root.options.forEach(getSelectedOptionsInSubtree);
    };

    this.options.forEach(getSelectedOptionsInSubtree);
    return selectedOptions;
  }

  newTreeWithOption(optionValue, selected) {
    this.updateOption(this.options, optionValue, selected);
    return new OptionStateTree(this.options);
  }

  updateOption(treeNodes, optionValue, selected) {
    const updateChildren = (children, selected) => {
      children.forEach(c => {
        c.selected = selected;
        updateChildren(c.options, selected);
      });
    };

    for (let option of treeNodes) {
      if (option.value === optionValue && option.selected != selected) {
        option.selected = selected;
        updateChildren(option.options, selected);
        return true;
      }

      const updated = this.updateOption(option.options, optionValue, selected);
      if (updated) {
        const allChildrenSelected = option.options.reduce((prev, curr) => prev && curr.selected, true);
        if (option.value && option.selected != allChildrenSelected) {
          option.selected = allChildrenSelected;
          return true;
        }
      }
    }

    return false;
  }

  applySelectedStateToOptions(realOptions = []) {
    const valueToSelected = {};
    this.options.forEach(option => calculateValueToSelected(option, valueToSelected));
    const result = realOptions.map(ro => updateOptionWithSelectedState(valueToSelected, ro));
    return result;
  }
}
