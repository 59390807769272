// @ts-nocheck
goog.declareModuleId('yext.ui.components.select.root.SelectRoot');

import {DropdownTrigger} from '/ui/components/dropdowntrigger/dropdowntrigger';
import {Option as OptionModel} from '/ui/components/select/model/option';
import {optionStates} from '/ui/components/select/model/optionstates';
import {NativeSelectGroup} from '/ui/components/select/native/nativeselectgroup';
import {NativeSelectList} from '/ui/components/select/native/nativeselectlist';
import {NativeSelectOption} from '/ui/components/select/native/nativeselectoption';
import {NativeSelectPlaceholder} from '/ui/components/select/native/nativeselectplaceholder';
import {SelectDropdownContent} from '/ui/components/select/root/selectdropdowncontent';
import {Option} from '/ui/components/select/types/option';

export class SelectRoot extends React.Component {
  constructor(props) {
    super(props);

    this.buttonRef = React.createRef();

    this.setFocusOnSelectChange = this.setFocusOnSelectChange.bind(this);
  }

  setFocusOnSelectChange(selectedOptionsValues) {
    this.buttonRef.current.focus();
    this.props.onSelectChange(selectedOptionsValues);
  }

  render() {
    // TODO(amullings): Share code with SelectGroup
    let hasCascade = false;
    for (const option of this.props.options) {
      // TODO(amullings): Check for non-casccading groups
      if (option.options && option.options.length > 0) {
        hasCascade = true;
        break;
      }
    }
    const role = hasCascade ? 'tree' : 'listbox';

    let selectedOptions = [];
    for (const option of this.props.options) {
      if (Option.getStatus(option) === optionStates.SELECTED) {
        selectedOptions.push(option.value + ''); // coerce to string
      }
    }

    if (!this.props.multiselect) {
      selectedOptions = selectedOptions.join();
    }

    const flattened = OptionModel.flatten(this.props.options);

    return (
      <React.Fragment>
        <DropdownTrigger
          ref={this.buttonRef}
          label={this.props.label}
          ariaRole={role}
          alignMenu={this.props.alignMenu}
          chromeless={this.props.chromeless}
          onToggleDropdown={this.props.onToggleDropdown}
          onCloseDropdown={this.props.onCloseDropdown}
          dropdownExpanded={this.props.dropdownExpanded}
          focusOutDetection={'strong'}
          disabled={this.props.disabled}
          tid={this.props.tid}
          pendoId={this.props.pendoId}
          wrapperClassName={this.props.wrapperClassName}
          triggerClass={this.props.dropdownTriggerClass}
          trigger={this.props.trigger}
          allowContainerOverflow={this.props.allowContainerOverflow}
          primary={this.props.primary}
          ghost={this.props.ghost}
        >
          <SelectDropdownContent
            options={this.props.options}
            alignCascade={this.props.alignCascade}
            multiselect={this.props.multiselect}
            onOptionChange={this.props.onOptionChange}
            setFocusOnSelectChange={this.setFocusOnSelectChange}
            onCloseDropdown={this.props.onCloseDropdown}
            allowSearch={this.props.allowSearch}
            searchPlaceholder={this.props.searchPlaceholder}
            searchTerm={this.props.searchTerm}
            onSearch={this.props.onSearch}
            actionLink={this.props.actionLink}
            hasCascade={hasCascade}
            isVisible={this.props.dropdownExpanded}
            selectAll={this.props.selectAll}
            onSelectAll={this.props.onSelectAll}
            selectAllState={this.props.selectAllState}
            onSelectNone={this.props.onSelectNone}
          />
        </DropdownTrigger>
        <select
          ref={this.props.nativeElemRef}
          name={this.props.name}
          multiple={this.props.multiselect}
          value={selectedOptions}
          onChange={this.props.onNativeChange}
          className="ui-select-native btn-default"
          disabled={this.props.disabled}
        >
          <NativeSelectPlaceholder multiple={this.props.multiselect}>
            {this.props.label}
          </NativeSelectPlaceholder>
          <NativeSelectList>
            {flattened.map((option, index) =>
              option.options
                ? <NativeSelectGroup group={option} key={option.value || `select-group-${index}`}/> // See earlier comment on key
                : <NativeSelectOption option={option} key={option.value}/>,
            )}
          </NativeSelectList>
        </select>
      </React.Fragment>);
  }
}
