goog.declareModuleId('yext.ui.components.select.standard.SelectOptionLabel');

import {Option} from '/ui/components/select/types/option';

import * as styles from '/ui/components/select/standard/standard.module.scss';

/**
 * @param {{
 *   option: Option,
 * }} props
 * @returns {React.ReactElement}
 */
export function SelectOptionLabel({
  option,
}) {
  const optionIconMarkup = option.icon ? (
    <span className={styles.selectOptionIcon} tid="option-icon">{option.icon}</span>
  ) : null;

  const optionSecondaryTextMarkup = option.secondaryText ? (
    <p className={styles.selectSecondaryText} tid="option-secondary-text">{option.secondaryText}</p>
  ) : null;

  return (
    <span className={`${styles.selectLabel} ${styles.selectLabelContainer}`}>
      {optionIconMarkup}
      <span>
        {option.label}
        {optionSecondaryTextMarkup}
      </span>
    </span>
  );
}
