import * as styles from "/tools/js/svg/templates/svg.module.scss";
const SvgMediumX = ({ title, titleId, ...props }) => {
  props = Object.assign({}, props, {
    "aria-hidden": true,
  });
  if (!props.noAlign) {
    const className = (props.className || "") + " " + styles.alignIcon;
    props = Object.assign({}, props, {
      className,
    });
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.5 4 16 2.5l-6 6-6-6L2.5 4l6 6-6 6L4 17.5l6-6 6 6 1.5-1.5-6-6z"
      />
    </svg>
  );
};
export default SvgMediumX;
