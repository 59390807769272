require('/ui/components/input/input.module.scss.module.css');

export const inputContainer = "_input-container_16jmt_1";
export const input = "_input_16jmt_1";
export const smallViewportSupport = "_small-viewport-support_16jmt_1";
export const iconButton = "_iconButton_16jmt_1";
export const numberStep = "_numberStep_16jmt_1 _iconButton_16jmt_1";
export const numberStepDown = "_numberStepDown_16jmt_1 _numberStep_16jmt_1 _iconButton_16jmt_1";
export const numberStepUp = "_numberStepUp_16jmt_1 _numberStep_16jmt_1 _iconButton_16jmt_1";
export const searchButton = "_searchButton_16jmt_1 _iconButton_16jmt_1";
export const label = "_label_16jmt_1";
export const embeddedFields = "_embedded-fields_16jmt_1";
