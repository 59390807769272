import * as styles from "/tools/js/svg/templates/svg.module.scss";
const SvgCheck = ({ title, titleId, ...props }) => {
  props = Object.assign({}, props, {
    "aria-hidden": true,
  });
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M13 4.758a.76.76 0 0 0-.199-.516l-.965-1.03A.667.667 0 0 0 11.354 3a.667.667 0 0 0-.483.212L6.215 8.19 4.13 5.955a.667.667 0 0 0-.483-.213.667.667 0 0 0-.482.213L2.2 6.985A.76.76 0 0 0 2 7.5c0 .19.071.379.199.515l3.534 3.773a.667.667 0 0 0 .482.212.667.667 0 0 0 .483-.212l6.103-6.515A.76.76 0 0 0 13 4.758z" />
    </svg>
  );
};
export default SvgCheck;
