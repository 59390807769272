/**
 * @fileoverview Checkbox component React interface.
 * @author Lisa Liu (lliu@yext.com)
 */
// @ts-nocheck
goog.declareModuleId('yext.ui.components.Checkbox');

import {classNames} from '/ui/lib/classnames';
import {useUid} from '/ui/lib/uid';

import * as styles from '/ui/components/checkbox/checkbox.module.scss';
import Check from '/ui/components/checkbox/icons/check.svg';

/**
 *
 * @typedef {{
 *          helpText?: React.ReactNode,
 *          id?: string,
 *          label?: ?,
 *          onBlur?: Function,
 *          onFocus?: Function,
 *          onChange?: Function,
 *          tid?: string,
 *          pendoId?: string,
 *          value?: ?,
 *          checked?: boolean,
 *          disabled?: boolean,
 *          hideLabel?: boolean,
 *          indeterminate?: boolean,
 *          name?: string,
 *          readOnly?: boolean,
 *          noMargin?: boolean,
 *          centerContents?: boolean,
 *          DANGEROUS_NON_FOCUSABLE?: boolean,
 *          ref?: ?,
 *        }}
 */
export let CheckboxProps;

/** @type {function(!CheckboxProps): React.ReactElement} */
export const Checkbox = React.forwardRef(({
  helpText,
  id = '',
  label,
  onBlur,
  onFocus,
  onChange,
  tid,
  pendoId,
  value,
  checked = false,
  disabled = false,
  hideLabel = false,
  indeterminate = false,
  name = id,
  readOnly = false,
  noMargin = false,
  centerContents = false,
  // A non-focusable Checkbox should only be used in specific circumstances
  // where the Checkbox's functionality is provided in some other way.
  DANGEROUS_NON_FOCUSABLE = false,
}, ref) => {
  const checkboxClasses = classNames(
    `${styles.checkbox}`,
    {
      [`${styles.checkboxIndeterminate}`]: indeterminate,
      [`${styles.checkboxChecked}`]: checked,
    },
  );

  const labelTextClasses = classNames(
    `${styles.labelText}`,
    {
      [`${styles.labelTextHidden}`]: hideLabel,
    },
  );

  const labelTextMarkup = (
    <span className={labelTextClasses} tid="label-text">{label}</span>
  );

  const labelContentClasses = classNames(
    `${styles.labelContent}`,
    {
      [`${styles.labelContentHidden}`]: hideLabel,
    },
  );

  const containerContentClasses = classNames(
    `${styles.container}`,
    {
      [`${styles.containerWithHiddenLabel}`]: hideLabel,
      [`${styles.centerContents}`]: centerContents,
      [`${styles.readOnly}`]: readOnly,
    },
  );

  const checkboxRootContentClasses = classNames(
    `${styles.checkboxContainer}`,
    {
      [`${styles.checkboxContainerWithHiddenLabel}`]: hideLabel,
      [`${styles.noMargin}`]: noMargin,
    },
  );

  const labelContentMarkup = helpText ? (
    <div className={labelContentClasses}>
      {labelTextMarkup}
      <small className={`${styles.labelHelpText}`}>{helpText}</small>
    </div>
  ) : labelTextMarkup;

  const checkboxIconMarkup = indeterminate ? (
    <span className={`${styles.checkboxIconIndeterminate}`}></span>
  ) : (
    <Check className={`${styles.checkboxIconChecked}`} />
  );

  // Used to pass indeterminate property to input (checkbox) without throwing errors
  const indeterminateRef = checkbox => {
    if (checkbox) {
      checkbox.indeterminate = indeterminate;
    }
  };

  const uid = useUid();

  return (
    <div className={containerContentClasses} tid={tid}>
      <label
        htmlFor={id || uid}
        className={`${styles.label}`}
      >
        <span className={checkboxRootContentClasses} ref={ref}>
          <input
            className={checkboxClasses}
            checked={checked}
            disabled={disabled}
            id={id || uid}
            data-pendo={pendoId}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            readOnly={readOnly}
            ref={indeterminateRef}
            type="checkbox"
            value={value}
            tabIndex={DANGEROUS_NON_FOCUSABLE ? -1 : 0}
          />
          <span className={`${styles.checkboxControl}`}></span>
          <span className={`${styles.checkboxIcon}`}>
            {checkboxIconMarkup}
          </span>
        </span>
        {labelContentMarkup}
      </label>
    </div>
  );
});
