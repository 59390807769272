/**
 * @fileoverview Creates a context to allow teams to create a pendo scope hierarchy
 * for easily concatenating pendo tags based on the component hierarchy.
 *
 * Other components in the UI Library can use this context to generate pendo tags
 * automatically based on the context they find themselves in.
 *
 * Example:
 * <PendoScopeProvider tag="my-component">
 *   <Link href="https://www.yext.com" tid="sl-yext">Click me</Link>
 * </PendoScopeProvider>
 *
 * Will generate a data-pendo attirbute of "my-component-link" on the anchor tag inside
 * the link component.
 *
 * This works in series, so if you have a component that is wrapped in multiple scopes
 * it will produce a compound tag.
 *
 * Example:
 *
 * <PendoScopeProvider tag="my-page">
 *   <PendoScopeProvider tag="header">
 *     <Button tid="sl-button">Click me</Button>
 *   </PendoScopeProvider>
 * </PendoScopeProvider>
 *
 * Will generate a data-pendo attribute of "my-page-header-button" on the button.
 *
 * @author Ben McGinnis (bmcginnis@yext.com)
 */

/**
 * Pendo holds the pendo tag for the current scope.
 */
export const PendoContext = React.createContext('');

/**
 * PendoScopeProviderProps is the props for the PendoScopeProvider component.
 *
 * @typedef {{
 *   children?: React.ReactNode,
 *   tag: string,
 * }}
 */
export let PendoScopeProviderProps;

/**
 * PendoScopeProvider is a React context provider that will combine the passed in tag
 * with any parent tags to create a new pendo scope for children components to use when
 * generating their data-pendo attributes.
 *
 * @param {PendoScopeProviderProps} props
 * @returns {React.ReactElement}
 */
export const PendoScopeProvider = ({children, tag}) => {
  const combinedTag = usePendoTag(tag);
  return (
    <PendoContext.Provider value={combinedTag}>
      {children}
    </PendoContext.Provider>
  );
};

/**
 * usePendoTag will generate a pendo tag from the parent tag and the tag passed in.
 * @param tag
 * @returns {string}
 */
export function usePendoTag(tag) {
  const parentTag = usePendoContext();
  return concatScopes(parentTag, tag);
}


/**
 * usePendoContext will return the concatenated pendo tag from the full hierarchy of
 * pendo scope providers in the component hierarchy.
 * @returns {string}
 */
export function usePendoContext() {
  return React.useContext(PendoContext);
}

/**
 * usePendoId will use a pendoId if it is passed in, and otherwise will generate
 * a pendoId from the fallback provided and the pendo context.
 * @param fallback
 * @param id
 * @returns {string}
 */
export function usePendoId(fallback, id) {
  const tag = usePendoTag(fallback);
  return id ? id : tag;
}

/**
 * concatScopes will slugify and then concatenate two pendo scopes with a hyphen.
 *
 * @param scopes {...string}
 * @returns {string}
 */
export function concatScopes(...scopes) {
  return scopes
    .filter(scope => !!scope)
    .map(slugify)
    .join('-');
}

/**
 * slugify will convert a string to a slug by lower casing and then stripping special
 * characters and converting them to hyphens.
 *
 * @param str {string}
 * @returns {string}
 */
export function slugify(str) {
  return (
    str
      .toLowerCase()
      .trim()
      // deburr accented characters
      .normalize('NFKD')
      // strip special characters
      .replace(/[^\w\s-]|[\s-]+/g, '-')
      .replace(/(-)\1+/g, '$1')
      .replace(/-$/, '')
  );
}
