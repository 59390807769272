import * as styles from "/tools/js/svg/templates/svg.module.scss";
const SvgArrowUp = ({ title, titleId, ...props }) => {
  props = Object.assign({}, props, {
    "aria-hidden": true,
  });
  if (!props.noAlign) {
    const className = (props.className || "") + " " + styles.alignIcon;
    props = Object.assign({}, props, {
      className,
    });
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.46 11.855a.308.308 0 0 0-.089-.205l-4.16-4.16a.308.308 0 0 0-.206-.09.308.308 0 0 0-.205.09l-4.16 4.16a.308.308 0 0 0-.09.205c0 .072.036.152.09.206l.446.446c.053.054.134.09.205.09a.308.308 0 0 0 .205-.09l3.51-3.509 3.508 3.51a.31.31 0 0 0 .206.088.29.29 0 0 0 .205-.089l.446-.446a.308.308 0 0 0 .09-.206z"
      />
    </svg>
  );
};
export default SvgArrowUp;
