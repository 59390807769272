/**
 * @fileoverview Tooltip component React interface.
 * @author Lisa Liu (lliu@yext.com)
 */
// @ts-nocheck
goog.declareModuleId('yext.ui.components.tooltip');

import Box from 'goog:goog.math.Box';
import positioning from 'goog:goog.positioning';
import AnchoredPosition from 'goog:goog.positioning.AnchoredPosition';
import Corner from 'goog:goog.positioning.Corner';
import Overflow from 'goog:goog.positioning.Overflow';
import OverflowStatus from 'goog:goog.positioning.OverflowStatus';

import {classNames} from '/ui/lib/classnames';
import {yext} from '/ui/lib/msg';
import {useUid} from '/ui/lib/uid';

import * as styles from '/ui/components/tooltip/tooltip.module.scss';

const {useFocusVisible} = /** @type {typeof _react_aria$interactions} */ (require('@react-aria/interactions'));

class TooltipPosition extends AnchoredPosition {
  /**
   * @param {Element} anchorElement Element the movable element should be
   *     anchored against.
   * @param {Corner} corner Corner of anchored element the
   *     movable element should be positioned at.
   * @param {boolean} vertical
   */
  constructor(anchorElement, corner, vertical) {
    super(anchorElement, corner);
    this.vertical_ = vertical;
  }

  /**
   * Repositions the movable element.
   *
   * @param {Element} movableElement Element to position.
   * @param {Corner} movableCorner Corner of the movable element
   *     that should be positioned adjacent to the anchored element.
   * @param {Box=} opt_margin A margin specified in pixels.
   * @param {?=} opt_preferredSize The preferred size of the
   *     movableElement.
   * @override
   */
  reposition(movableElement, movableCorner, opt_margin, opt_preferredSize) {
    const fail = this.vertical_ ? Overflow.FAIL_Y : Overflow.FAIL_X;
    const adjust = this.vertical_ ? Overflow.ADJUST_Y : Overflow.ADJUST_X;

    const status = positioning.positionAtAnchor(
      this.element,
      this.corner,
      movableElement,
      movableCorner,
      null,
      opt_margin,
      fail,
      opt_preferredSize,
    );

    if (status & OverflowStatus.FAILED) {
      // If that fails, adjust the position until it fits.
      positioning.positionAtAnchor(
        this.element,
        this.corner,
        movableElement,
        movableCorner,
        null,
        opt_margin,
        adjust,
        opt_preferredSize,
      );
    }
  }
}

/**
 * @param {{
 *         children?: React.ReactNode,
 *         direction?: string,
 *         hasDelay?: boolean,
 *         maxWidth?: string,
 *         tid?: string,
 *         pendoId?: string,
 *         tooltipHeader?: React.ReactNode,
 *         tooltipText?: React.ReactNode,
 *         unclearText?: React.ReactNode,
 *         ignoreTabIndex?: boolean,
 *         light?: boolean,
 *         isInline?: boolean,
 *        }} props
 * @returns {React.ReactElement}
 */
export function Tooltip({
  children,
  direction = 'bottom',
  hasDelay = false,
  maxWidth = '160px',
  tid,
  pendoId,
  tooltipHeader,
  tooltipText,
  unclearText,
  ignoreTabIndex = false,
  light = false,
  isInline = false,
}) {
  const unclearRef = React.useRef(null);
  const unclear = unclearRef.current;
  const [visible, setVisible] = React.useState(false);

  const headerId = '__yext_tooltip_header_' + useUid();
  const textId = '__yext_tooltip_text_' + useUid();

  // TODO(UX-1544): Replace delay behavior with hovering behavior
  let popupDelay = undefined;
  const hidePopup = () => {
    popupDelay = setTimeout(() => {
      setVisible(false);
    }, hasDelay ? 2000 : 0);
  };
  const clearPopupDelay = () => clearTimeout(popupDelay);

  return (
    /*
      Don't remove "yext-tooltip__container" as it's currently used for styling
      in a few places
    */
    <div
      className={classNames(isInline ? styles.inlineBlock : '', 'yext-tooltip__container')} tid={tid}
    >
      <span className={styles.unclear}>
        {
          unclearText
            && <span>
              {unclearText}
            </span>
        }
        <span
          className={children ? '' : styles.unclear__icon + ' icon-tooltip'}
          ref={unclearRef}
          role={ignoreTabIndex ? null : 'img'}
          tabIndex={ignoreTabIndex ? '-1' : '0'}
          onFocus={() => {
            clearPopupDelay();
            setVisible(true);
          }}
          onMouseOver={() => {
            clearPopupDelay();
            setVisible(true);
          }}
          onBlur={() => hidePopup()}
          onMouseOut={() => hidePopup()}
          aria-label={tooltipHeader ? undefined : yext.msg('More Info')}
          aria-labelledby={tooltipHeader ? headerId : undefined}
          aria-describedby={textId}
          data-pendo={pendoId}
        >
          {children}
        </span>
        <Bubble
          direction={direction}
          maxWidth={maxWidth}
          targetRef={unclearRef}
          visible={visible}
          light={light}
        >
          {
            tooltipHeader
              && <div id={headerId} className={styles.content__header}>{tooltipHeader}{
                ' ' // ensure there is always a space between header and text
              }</div>
          }
          <div id={textId} className={styles.content__text}>
            {tooltipText}
          </div>
        </Bubble>
      </span>
    </div>
  );
}

/**
 * @param {string} direction
 * @return {string}
 */
function getDirectionClassName(direction) {
  switch (direction) {
    case 'top':
      return styles.top;
    case 'right':
      return styles.right;
    case 'left':
      return styles.left;
    default:
      return styles.bottom;
  }
}

/**
 * @param {{
 *         direction: (string|undefined),
 *         target: ?HTMLElement,
 *         arrow: ?HTMLElement,
 *         content: ?HTMLElement,
 *        }} options
 */
function repositionTooltip({direction, target, arrow, content}) {
  if (!target || !arrow || !content) {
    return;
  }

  let insideCorner = Corner.TOP_CENTER;
  let outsideCorner = Corner.BOTTOM_CENTER;
  let arrowMargin = null;
  let contentMargin = undefined;
  let verticalOverflow = false;
  const iconHeight = target.offsetHeight;
  const arrowHeight = arrow.offsetHeight;
  const contentHeight = content.offsetHeight;
  switch (direction) {
    case 'top':
      insideCorner = Corner.TOP_CENTER;
      outsideCorner = Corner.BOTTOM_CENTER;
      verticalOverflow = false;
      break;
    case 'right':
      insideCorner = Corner.TOP_RIGHT;
      outsideCorner = Corner.TOP_LEFT;
      arrowMargin = new Box(iconHeight / 2 - arrowHeight / 2, 0, 0, 0);
      contentMargin = new Box(arrowHeight / 2 - contentHeight / 2, 0, 0, 0);
      verticalOverflow = true;
      break;
    case 'left':
      insideCorner = Corner.TOP_LEFT;
      outsideCorner = Corner.TOP_RIGHT;
      arrowMargin = new Box(iconHeight / 2 - arrowHeight / 2, 0, 0, 0);
      contentMargin = new Box(arrowHeight / 2 - contentHeight / 2, 0, 0, 0);
      verticalOverflow = true;
      break;
    default:
      insideCorner = Corner.BOTTOM_CENTER;
      outsideCorner = Corner.TOP_CENTER;
      verticalOverflow = false;
  }
  const tooltipPosition = new AnchoredPosition(target, insideCorner);
  const contentPosition = new TooltipPosition(arrow, insideCorner, verticalOverflow);
  tooltipPosition.reposition(arrow, outsideCorner, arrowMargin);
  contentPosition.reposition(content, outsideCorner, contentMargin);
}

/**
 * @param {{
 *         children?: React.ReactNode,
 *         direction?: string,
 *         iconStyle?: boolean,
 *         id?: string,
 *         maxWidth?: string,
 *         targetRef: !React.RefObject<HTMLElement>,
 *         visible?: (boolean|null),
 *         light?: boolean,
 *         role?: string,
 *        }} props
 * @return {?}
 */
export function Bubble({
  children,
  direction = 'bottom',
  iconStyle,
  id,
  maxWidth,
  targetRef,
  visible = false,
  light = false,
  role = 'tooltip',
}) {
  const contentRef = React.useRef(null);
  const content = contentRef.current;
  const arrowRef = React.useRef(null);
  const arrow = arrowRef.current;
  const [portalEl, setPortalEl] = React.useState(/** @type {?} */ (null));

  React.useEffect(() => {
    const el = document.createElement('div');
    el.style.position = 'relative';
    /*
      Value is equal to tooltip z-index styles.
      Needed to allow Tooltips to appear in Modals.
    */
    el.style.zIndex = '20';
    document.body.appendChild(el);
    setPortalEl(el);

    return function cleanup() {
      document.body.removeChild(el);
    };
  }, []);

  /*
    Using useLayoutEffect instead of useEffect prevents the bubble from being
    visible in the wrong place for a brief instance the first time it is shown
  */
  React.useLayoutEffect(() => {
    if (visible && targetRef.current) {
      repositionTooltip({
        direction,
        target: targetRef.current,
        arrow,
        content,
      });
    }
  }, [visible, direction, targetRef, arrow, content]);

  // Reposition on every frame
  React.useEffect(() => {
    if (!visible) {
      return;
    }

    let req;

    const reposition = () => {
      if (targetRef.current) {
        repositionTooltip({
          direction,
          target: targetRef.current,
          arrow,
          content,
        });
      }

      req = window.requestAnimationFrame(reposition);
    };

    req = window.requestAnimationFrame(reposition);

    // clean up
    return () => window.cancelAnimationFrame(req);
  }, [arrow, content, direction, targetRef, visible]);

  return portalEl
    && ReactDOM.createPortal(
      <>
        <div
          role={role}
          id={id}
          className={classNames(
            light ? styles.contentLight : styles.contentDark,
            {[styles.iconTooltip]: iconStyle},
          )}
          ref={contentRef}
          style={{
            display: visible ? undefined : 'none',
            maxWidth,
            visibility: visible ? 'visible' : 'hidden',
          }}
        >
          {children}
        </div>
        <div
          className={classNames(
            light ? styles.arrowLight : styles.arrowDark,
            getDirectionClassName(direction),
            {[styles.iconTooltip]: iconStyle},
          )}
          ref={arrowRef}
          style={{
            display: visible ? undefined : 'none',
            visibility: visible ? 'visible' : 'hidden',
            zIndex: 20,
          }}
        />
      </>,
      portalEl);
}

/**
 * @param {{
 *   buttonClassName?: string,
 *   buttonId?: string,
 *   buttonStyle?: !Object,
 *   children?: React.ReactNode,
 *   direction?: string,
 *   onClick: (function(?): void),
 *   pendoId?: string,
 *   tooltipText: React.ReactNode,
 * }} props
 * @return {React.ReactElement}
 */
export function TooltipButton({
  buttonClassName,
  buttonId,
  buttonStyle,
  children,
  direction,
  onClick,
  pendoId,
  tooltipText,
}) {
  const [visible, setVisible] = React.useState(false);
  const buttonRef = React.useRef(null);
  const tooltipId = '__yext_tooltip_' + useUid();

  return (
    <>
      <button
        type="button"
        className={buttonClassName}
        id={buttonId}
        data-pendo={pendoId}
        onClick={onClick}
        onFocus={() => setVisible(true)}
        onMouseOver={() => setVisible(true)}
        onBlur={() => setVisible(false)}
        onMouseOut={() => setVisible(false)}
        ref={buttonRef}
        style={buttonStyle}
        aria-describedby={tooltipId}
      >
        {children}
      </button>
      <Bubble
        iconStyle
        direction={direction}
        id={tooltipId}
        targetRef={buttonRef}
        visible={visible}
      >
        <div className={styles.content__text}>
          {tooltipText}
        </div>
      </Bubble>
    </>
  );
}

/**
 * @typedef {{
 *   element?: ?,
 *   tooltipHeader?: React.ReactNode,
 *   tooltipText?: React.ReactNode,
 *   direction?: string,
 *   light?: boolean,
 *   delay?: number,
 *   targetRef?: ?,
 *   maxWidth?: string,
 *   iconStyle?: boolean,
 *   visible?: (boolean|null),
 * }}
 */
export let TooltipProps;

/**
 * @param {TooltipProps} props
 * @return {React.ReactElement}
 */
export function IconTooltip({
  element,
  tooltipHeader,
  tooltipText,
  direction,
  light,
  delay = 500,
  targetRef,
  visible,
  maxWidth,
  iconStyle = true,
}) {
  const innerRef = React.useRef(null);
  const ref = targetRef || innerRef;
  const [visibleFromMouse, setVisibleFromMouse] = React.useState(false);
  const [visibleFromFocus, setVisibleFromFocus] = React.useState(false);
  /** @type {?} */
  const timeoutRef = React.useRef(null);
  const {isFocusVisible} = useFocusVisible();
  if (!(visible === true || visible === false)) {
    visible = visibleFromMouse || (isFocusVisible && visibleFromFocus);
  }

  const isMounted = useIsMounted();
  const headerId = '__yext_tooltip_header_' + useUid();
  const textId = '__yext_tooltip_text_' + useUid();

  return (
    <>
      <element.type
        {...element.props}
        onFocus={() => {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            if (!isMounted.current) {
              return;
            }

            setVisibleFromFocus(true);
          }, delay);
        }}
        onBlur={() => {
          clearTimeout(timeoutRef.current);
          setVisibleFromFocus(false);
        }}
        onMouseOver={() => {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            if (!isMounted.current) {
              return;
            }

            setVisibleFromMouse(true);
          }, delay);
        }}
        onMouseOut={() => {
          clearTimeout(timeoutRef.current);
          setVisibleFromMouse(false);
        }}
        ref={ref}
        aria-labelledby={tooltipHeader ? headerId : textId}
        aria-describedby={tooltipHeader ? textId : undefined}
      />
      <Bubble
        iconStyle={iconStyle}
        direction={direction}
        targetRef={ref}
        visible={visible}
        light={light}
        maxWidth={maxWidth}
      >
        {
          tooltipHeader
            && <div id={headerId} className={styles.content__header}>{tooltipHeader}{
              ' ' // ensure there is always a space between header and text
            }</div>
        }
        <div id={textId} className={styles.content__text}>
          {tooltipText}
        </div>
      </Bubble>
    </>
  );
}

const useIsMounted = () => {
  /** @type {?} */
  const isMounted = React.useRef(null);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};
