require('/ui/components/tooltip/tooltip.module.scss.module.css');

export const inlineBlock = "_inline-block_1astg_1";
export const content = "_content_1astg_1";
export const contentDark = "_content-dark_1astg_1 _content_1astg_1";
export const contentLight = "_content-light_1astg_1 _content_1astg_1";
export const iconTooltip = "_icon-tooltip_1astg_1";
export const content__header = "_content__header_1astg_1";
export const content__text = "_content__text_1astg_1";
export const smallViewportSupport = "_small-viewport-support_1astg_1";
export const unclear = "_unclear_1astg_1";
export const unclear__icon = "_unclear__icon_1astg_1";
export const arrow = "_arrow_1astg_1";
export const arrowDark = "_arrow-dark_1astg_1 _arrow_1astg_1";
export const bottom = "_bottom_1astg_1";
export const top = "_top_1astg_1";
export const right = "_right_1astg_1";
export const left = "_left_1astg_1";
export const arrowLight = "_arrow-light_1astg_1 _arrow_1astg_1";
