// @ts-nocheck
goog.declareModuleId('yext.ui.components.select.standard.SelectGroup');

import {SelectList} from '/ui/components/select/standard/selectlist';
import {SelectOption} from '/ui/components/select/standard/selectoption';
import {SelectGroupHeading} from '/ui/components/select/standard/selectgroupheading';
import {MultiSelectOption} from '/ui/components/select/standard/multiselectoption';
import {optionStates} from '/ui/components/select/model/optionstates';
import {Option} from '/ui/components/select/types/option';

export class SelectGroup extends React.Component {
  render() {
    let hasCascade = false;
    for (const option of this.props.group.options) {
      // TODO(amullings): Check for non-casccading groups
      if (option.options && option.options.length > 0) {
        hasCascade = true;
        break;
      }
    }
    // TODO(amullings): Check for non-casccading groups
    const alignCascade = this.props.alignCascade;
    const isCascade = true;
    const GroupHeading = this.props.multiselect && this.props.group.value ? MultiSelectOption : SelectGroupHeading;
    const OptionComponent = this.props.multiselect ? MultiSelectOption : SelectOption;
    // use string keys to prevent advanced compilation
    const ariaAttributes = {'role': 'treeitem'};
    return (
      <GroupHeading
        option={this.props.group}
        onOptionChange={this.props.onOptionChange}
        checked={Option.getStatus(this.props.group) === optionStates.SELECTED}
        indeterminate={Option.getStatus(this.props.group) === optionStates.INDETERMINATE}
        ariaAttributes={ariaAttributes}
      >
        <SelectList isCascade={isCascade} hasCascade={hasCascade} alignCascade={alignCascade} isVisible={this.props.isVisible}>
          {this.props.group.options.map(option => option.options && option.options.length > 0
            ? <SelectGroup
              group={option}
              key={option.value}
              onOptionChange={this.props.onOptionChange}
              onSelectChange={this.props.onSelectChange}
              multiselect={this.props.multiselect}
              alignCascade={alignCascade}
              isVisible={this.props.isVisible} />
            : <OptionComponent
              option={option}
              key={option.value}
              onOptionChange={this.props.onOptionChange}
              onSelectChange={this.props.onSelectChange}
              checked={Option.getStatus(option) === optionStates.SELECTED}
              indeterminate={Option.getStatus(option) === optionStates.INDETERMINATE}
              ariaAttributes={ariaAttributes} />)}
        </SelectList>
      </GroupHeading>
    );
  }
}
