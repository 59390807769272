// @ts-nocheck
goog.declareModuleId('yext.ui.components.select.standard.SelectList');

import AnchoredViewportPosition from 'goog:goog.positioning.AnchoredViewportPosition';
import Corner from 'goog:goog.positioning.Corner';

import {KeyboardAccessibleList} from '/ui/components/keyboardaccessiblelist/keyboardaccessiblelist';
import {menuAim, ReturnFuncsType} from '/ui/components/select/menuaim/menuaim';
import {logError} from '/ui/lib/errors';

const LIST_ITEM_SELECTOR = '.js-select-option:not(.js-select-option-disabled)';

/**
 * @param {HTMLElement} elem the parent element to query
 * @returns {Element|null} The sublist element, or null if it could not be found.
 */
function getSublist(elem) {
  if (!elem) {
    return null;
  }
  // Returns null if no match
  return elem.querySelector(':scope > .js-select-cascade');
}

function getAllSublists(elem) {
  if (!elem) {
    return [];
  }
  return elem.querySelectorAll('.js-select-cascade');
}

/**
 * @param {HTMLElement} row
 * @returns {Element|null}
 */
function activateRow(row) {
  const sublist = getSublist(row);
  if (sublist) {
    sublist.classList.add('ui-select-cascade-expanded');
    row.setAttribute('aria-expanded', 'true');
    const position = new AnchoredViewportPosition(row, Corner.TOP_END, true);
    position.reposition(sublist, Corner.TOP_START);
    return sublist;
  }
  return null;
}

/**
 * @param {HTMLElement} row
 */
function deactivateRow(row) {
  const mainSublist = getSublist(row);
  if (mainSublist) {
    row.setAttribute('aria-expanded', 'false');
  }
  if (mainSublist && mainSublist.contains(document.activeElement)) {
    // Move focus so that keyboard users don't get stranded
    focus(row);
  }
  for (const sublist of getAllSublists(row)) {
    sublist.classList.remove('ui-select-cascade-expanded');
  }
}

/**
 * @param {HTMLElement} item
 * @returns {boolean}
 */
function isFirstOption(item) {
  let previousItem = item.previousSibling;
  while (previousItem && !previousItem.classList.contains('js-select-option')) {
    previousItem = previousItem.previousSibling;
  }
  if (!previousItem) {
    return true;
  }
  return false;
}

function handleKeyboardNavigation(event, menuAimRefCurrent) {
  const currentItem = /** @type {HTMLElement|null} */(document.activeElement?.closest(LIST_ITEM_SELECTOR));
  switch (event.key) {
    case 'ArrowRight': {
      event.preventDefault();
      if (currentItem) {
        const sublist = activateRow(currentItem);
        if (sublist) {
          const nextItem = Array.from(/** @type {!NodeList<!HTMLElement>} */(sublist.children))
            .find(e => e.matches(LIST_ITEM_SELECTOR));
          if (nextItem) {
            focus(nextItem);
          }
        }
        if (menuAimRefCurrent) {
          menuAimRefCurrent.deactivateRow();
        }
      }
      break;
    }
    case 'ArrowLeft': {
      event.preventDefault();
      const parent = /** @type {HTMLElement|null} */(currentItem?.parentElement?.closest(LIST_ITEM_SELECTOR));
      if (parent) {
        deactivateRow(parent);
        menuAimRefCurrent && menuAimRefCurrent.deactivateRow();
      }
      break;
    }
    case 'ArrowUp':
      event.preventDefault();
      if (currentItem && isFirstOption(currentItem)) {
        if (currentItem.parentNode) {
          const previousItem = currentItem.parentNode.previousSibling;
          if (previousItem) {
            previousItem.focus();
          }
        }
      }
      break;
    case 'ArrowDown':
      event.preventDefault();
      if (currentItem && !currentItem.nextSibling) {
        const nextItem = currentItem.parentNode.nextSibling;
        if (nextItem && nextItem.firstChild) {
          nextItem.firstChild.focus();
        }
      }
  }
}

/**
 * @param {HTMLElement} listItem
 */
function focus(listItem) {
  let target = listItem.querySelector(':scope > .js-select-target');
  if (!listItem) {
    logError('KeyboardAccessibleList: Could not focus (null listItem)');
  }
  // If the target is a label, we want to focus it's associated control.
  target = target.control || target;
  if (!target) {
    logError('KeyboardAccessibleList: Could not focus (null target)');
  }
  target.focus();
}

/**
 * @typedef {{
 *   alignCascade?: string,
 *   isCascade: boolean,
 *   hasCascade: boolean,
 *   tabIndex?: (number|string),
 *   ariaAttributes?: !Object,
 *   children?: React.ReactNode,
 *   isVisible: boolean,
 *   height?: (number|undefined),
 *   listHeightCallback?: (function((number|undefined)): void),
 * }}
 */
export let SelectListProps;

/**
 * @param {SelectListProps} props
 * @return {React.ReactElement}
 */
export function SelectList(props) {
  const listRef = React.useRef(/** @type {HTMLElement|null} */(null));
  const menuAimRef = React.useRef(/** @type {ReturnFuncsType|undefined|null} */(null));

  React.useEffect(() => {
    if (props.listHeightCallback) {
      props.listHeightCallback(listRef.current?.offsetHeight);
    }
  });

  const classList = ['ui-select-list'];
  if (props.isCascade) {
    classList.push('ui-select-cascade js-select-cascade');
  }
  if (props.alignCascade === 'left') {
    classList.push('ui-select-cascade--align-left');
  } else {
    classList.push('ui-select-cascade--align-right');
  }

  React.useEffect(() => {
    const handler = event => {
      if (menuAimRef.current) {
        // Disable menuAim while interacting with the list via keyboard, to
        // prevent things like page scrolling from activating rows.
        // TODO(amullings): Properly deactivate rows when switching between
        // keyboard and mouse
        menuAimRef.current.disable();
      }
      if (!props.isCascade) {
        // Keyboard events get bubbled, so we only want to handle them on the
        // top-level list
        handleKeyboardNavigation(event, menuAimRef.current);
      }
    };
    if (listRef.current) {
      listRef.current.addEventListener('keydown', handler);
    }
    const listRefCurrent = listRef.current;
    return function remove() {
      if (listRefCurrent) {
        listRefCurrent.removeEventListener('keydown', handler);
      }
    };
  });

  React.useEffect(() => {
    if (props.hasCascade && listRef.current) {
      const menu = listRef.current;
      const currentMenuAim = menuAim(menu, {
        rowElements: menu ? menu.querySelectorAll(`:scope > ${LIST_ITEM_SELECTOR}`) : [],
        submenuFilter: row => getSublist(row),
        activate: activateRow,
        deactivate: deactivateRow,
        tolerance: 75,
      });
      menuAimRef.current = currentMenuAim;
      return function remove() {
        currentMenuAim?.removeEventListener();
      };
    }
  }, [props.hasCascade]);

  React.useEffect(() => {
    if (menuAimRef.current && listRef.current) {
      /* update menuAim rowElements */
      const menu = listRef.current;
      const currentRowElements = menu ? menu.querySelectorAll(`:scope > ${LIST_ITEM_SELECTOR}`) : [];
      menuAimRef.current.updateMenu({currentMenu: menu, currentRowElements: currentRowElements});
    }
  }, [props.children]);

  React.useEffect(() => {
    if (!props.isVisible) {
      menuAimRef.current && menuAimRef.current.deactivateRow();
      const rows = listRef.current?.querySelectorAll(LIST_ITEM_SELECTOR);
      if (rows) {
        for (const row of rows) {
          row.ariaExpanded && deactivateRow(/** @type {HTMLElement} */(row));
        }
      }
    }
  }, [props.isVisible]);

  return (
    <KeyboardAccessibleList
      ref={listRef}
      tabIndex={props.tabIndex}
      classList={classList}
      itemSelector={LIST_ITEM_SELECTOR}
      focusTargetSelector=":scope > .js-select-target"
      onUnselect={deactivateRow}
      // Keyboard events get bubbled, so we only want to handle them on the
      // top-level list
      ignoreKeydown={props.isCascade}
      ariaAttributes={props.ariaAttributes}
      height={props.height}
    >
      {props.children}
    </KeyboardAccessibleList>
  );
}
