// @ts-nocheck
goog.declareModuleId('yext.ui.components.select.standard.SelectActionLink');

/** @param {{actionLink: ?,
 *           onCloseDropdown?: (function():void)}} props
 *  @returns {React.ReactElement}
 */
export function SelectActionLink(props) {
  const actionLinkWrapper = () => {
    const {actionLink, onCloseDropdown} = props;

    if (actionLink.onClick) {
      actionLink.onClick();
      onCloseDropdown();
    }
  };

  const {actionLink} = props;
  const actionLinkClass = 'yext-select__action-link';

  if (actionLink.path && actionLink.onClick) {
    throw new Error('You can provide either `path` or `onClick`, but not both.');
  }

  const handleKeyDown = event => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        const previousItem = event.target.parentNode.previousSibling;
        if (previousItem && previousItem.lastChild) {
          previousItem.lastChild.firstChild.focus();
        }
    }
  };

  const actionLinkMarkup = actionLink.onClick ? (
    <button
      className={actionLinkClass}
      onClick={actionLinkWrapper}
      type="button"
      data-pendo={actionLink.pendoId}
    >
      {actionLink.label}
    </button>
  ) : (
    <a href={actionLink.path} className={actionLinkClass} data-pendo={actionLink.pendoId}>{actionLink.label}</a>
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onKeyDown={handleKeyDown}>
      {actionLinkMarkup}
    </div>
  );
}
