goog.declareModuleId('yext.ui.components.select.standard.SelectGroupHeading');

import {SelectOptionLabel} from '/ui/components/select/standard/selectoptionlabel';

export function SelectGroupHeading(props) {
  return (
    <li
      className={`js-select-option ${props.additionalCssClass || ''}`}
      {...props.ariaAttributes}
      // use string keys to prevent advanced compilation
      {...{'aria-selected': props.checked}}
    >
      {props.children}
      <span
        tabIndex="-1"
        data-tabindex="-1"
        className="ui-select-row js-select-target"
      >
        <SelectOptionLabel option={props.option} />
      </span>
    </li>);
}
